import { useMemo } from 'react';
import { isEqual } from 'lodash';
import { VariableCategory, Variable } from 'api/data/variables';
import { InputType } from 'types/index';
import { initButtonProps } from 'helpers/buttons';
import { EditCategoryFixedValuesType } from 'consts';
import { Input } from 'components/UI/Inputs/Input';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import {
	useTranslation,
	useCreateVariableCategoryValue,
	useUpdateVariableCategoryValue
} from 'hooks/store';
import { useMutableState, useCompletedAction, useKeyPress } from 'hooks/utils';
import { initVariableCategory } from 'helpers/variables/variableHelpers';

interface CategoryValueProps {
	description: string;
	id: string;
	label: string;
	value: string;
}
interface CategoryValuesPayloadProps {
	categoryValue: CategoryValueProps;
	type: string;
	variableName: string;
}

interface TurnOffCategoryValuesModalProps {
	open: (payload?: CategoryValuesPayloadProps) => void;
}

interface Props {
	variable: Variable;
	categoryValue?: VariableCategory;
	turnOffCategoryValuesModal: TurnOffCategoryValuesModalProps;
	onClose: (success?: boolean) => void;
}

const defaultCategoryValue = initVariableCategory();

export function CategoryValueModal({
	variable,
	categoryValue,
	onClose,
	turnOffCategoryValuesModal
}: Props) {
	const { translate } = useTranslation();

	const isCreateMode = !categoryValue;
	const initialDraftCategoryValue = categoryValue ?? defaultCategoryValue;
	const [draftCategoryValue, setDraftCategoryValue] = useMutableState(
		categoryValue ?? defaultCategoryValue
	);

	const hasChanges = useMemo(
		() => !isEqual(initialDraftCategoryValue, draftCategoryValue),
		[draftCategoryValue]
	);

	const [
		{ loading: creatingCategoryValue, error: errorCreatingCategoryValue },
		createCategoryValue
	] = useCreateVariableCategoryValue();
	const [
		{ loading: updatingCategoryValue, error: errorUpdatingCategoryValue },
		updateCategoryValue
	] = useUpdateVariableCategoryValue();

	useCompletedAction(creatingCategoryValue, errorCreatingCategoryValue, () => onClose(true));
	useCompletedAction(updatingCategoryValue, errorUpdatingCategoryValue, () => onClose(true));

	useKeyPress(
		{ onEnterKeyPress: hasChanges ? handleSubmit : onClose },
		{ listen: !isLoadingAction() }
	);

	function handleSubmit() {
		if (!isFormValid()) return;

		if (isCreateMode) {
			createCategoryValue({
				variableName: variable.name,
				categoryValue: draftCategoryValue
			});
		} else {
			const changedCategory = variable.categories.find(c => c.id === draftCategoryValue.id);

			if (changedCategory && changedCategory.value !== draftCategoryValue.value) {
				turnOffCategoryValuesModal.open({
					variableName: variable.name,
					categoryValue: draftCategoryValue,
					type: EditCategoryFixedValuesType.CategoryFixedValuesChanged
				});
				return;
			}

			updateCategoryValue({
				variableName: variable.name,
				categoryValue: draftCategoryValue
			});
		}
	}

	function isFormValid() {
		const label = draftCategoryValue.label.trim();
		const value = draftCategoryValue.value.trim();

		// if `label` is empty BE assigns `value` to it
		if (!label) {
			if (!isLabelUnique(value)) return false;
		}

		return (
			hasChanges &&
			draftCategoryValue.value.trim().length > 0 &&
			isValueUnique(value) &&
			isLabelUnique(label)
		);
	}

	function isValueUnique(value: string): boolean {
		let computedCategories = variable.categories;

		if (categoryValue) {
			computedCategories = variable.categories.filter(c => c.id !== categoryValue.id);
		}

		return !computedCategories.some(c => c.value === value.trim());
	}

	function isLabelUnique(label: string): boolean {
		let computedCategories = variable.categories;

		if (categoryValue) {
			computedCategories = variable.categories.filter(c => c.id !== categoryValue.id);
		}

		return !computedCategories.some(c => c.label.length > 0 && c.label === label.trim());
	}

	function trimFields() {
		setDraftCategoryValue(state => {
			state.value = state.value.trim();
			state.label = state.label.trim();
			state.description = state.description.trim();
		});
	}

	function isLoadingAction(): boolean {
		return creatingCategoryValue || updatingCategoryValue;
	}

	const buttonProps = initButtonProps(buttons => {
		buttons.primary = {
			label: translate(({ buttons }) => buttons.confirm),
			loading: isLoadingAction(),
			disabled: !isFormValid(),
			onClick: handleSubmit
		};

		buttons.neutral = {
			label: translate(({ buttons }) => buttons.cancel),
			onClick: onClose
		};

		if (!isCreateMode && !hasChanges) {
			delete buttons.neutral;

			buttons.primary = {
				label: translate(({ buttons }) => buttons.done),
				onClick: onClose
			};
		}
	});

	return (
		<Modal
			size={s => s.s}
			title={`${
				isCreateMode
					? translate(dict => dict.terms.create)
					: translate(dict => dict.terms.edit)
			} ${translate(dict => dict.terms.value)}`}
			primary={buttonProps.primary}
			neutral={buttonProps.neutral}
			onClose={onClose}
			visible
			close
		>
			<Input
				label={translate(dict => dict.terms.valueName)}
				type={InputType.Text}
				value={draftCategoryValue.value}
				error={
					!isValueUnique(draftCategoryValue.value)
						? translate(dict => dict.terms.uniqueValue)
						: undefined
				}
				onChange={e =>
					setDraftCategoryValue(state => {
						state.value = e.target.value;
					})
				}
				onSubmit={handleSubmit}
				onBlur={trimFields}
				autoFocus={isCreateMode}
				dataTestId="category-value"
				required
			/>
			<Spacer size={s => s.m} />
			<Input
				label={translate(dict => dict.terms.label)}
				type={InputType.Text}
				value={draftCategoryValue.label}
				error={
					!isLabelUnique(draftCategoryValue.label)
						? translate(dict => dict.terms.labelUnique)
						: undefined
				}
				onChange={e =>
					setDraftCategoryValue(state => {
						state.label = e.target.value;
					})
				}
				onSubmit={handleSubmit}
				onBlur={trimFields}
				dataTestId="category-label"
			/>
			<Spacer size={s => s.m} />
			<Input
				label={translate(dict => dict.terms.description)}
				type={InputType.Text}
				value={draftCategoryValue.description}
				onChange={e =>
					setDraftCategoryValue(state => {
						state.description = e.target.value;
					})
				}
				onSubmit={handleSubmit}
				onBlur={trimFields}
				dataTestId="category-description"
			/>
		</Modal>
	);
}
