import { useState, useMemo, useEffect, useRef } from 'react';
import { NewVariableSet, NewGroup } from 'api/data/variables';
import { VariableType } from 'types/data/variables/constants';
import { InputType, SelectItem } from 'types/index';
import { VariablesTableCheckedState } from 'hooks/store/data/variables/useVariablesTableCheckedData';
import { ConfirmMoveVariablesToSetModal } from '../ConfirmMoveVariablesToSetModal';
import { Input } from 'components/UI/Inputs/Input';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Checkbox } from 'components/UI/Interactables/Checkbox';
import { Flex } from 'components/UI/Flex';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import { buildVariablesDataLocation, buildVariableSetVariablesData } from 'helpers/variables';
import {
	useTranslation,
	useIsMoveInProgress,
	useVariables,
	useVariablesData,
	useCreateGroup,
	useCreateVariableSet,
	useAddVariablesToGroup,
	useMoveVariablesBetweenGroups,
	useMoveVariablesOrGroupsToRootList,
	useMoveVariablesOrGroupsToSet
} from 'hooks/store';
import { useAlerts } from 'hooks/ui';
import { useCompletedAction } from 'hooks/utils';
import { RadioGroupUncontrolled } from 'components/UI/Interactables/Uncontrolled';
import { Option } from 'components/UI/Interactables/Uncontrolled/RadioGroup/RadioGroupUncontrolled';

interface Props {
	checkedState: VariablesTableCheckedState;
	disabledVariableTypesInSet: VariableType[];
	onClose: () => void;
}

enum Options {
	main = 'mainLevel',
	group = 'group',
	series = 'series'
}

enum MOVE_OPTIONS {
	start = 'beginning',
	end = 'end',
	before = 'before',
	after = 'after',
	index = 'index'
}

export function MoveSelectedVariablesToGroupModal({
	checkedState,
	disabledVariableTypesInSet,
	onClose
}: Props) {
	const { translate } = useTranslation();
	const [selectedVariables, setSelectedVariables] = useState<string[]>([]);
	const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
	const [mainSelectedVariables, setMainSelectedVariables] = useState<string[]>([]);
	const [mainSelectedGroups, setMainSelectedGroups] = useState<string[]>([]);
	const [moveOption, setMoveOption] = useState(MOVE_OPTIONS.start);
	const [beforeAfterInputValue, setBeforeAfterInputValue] = useState('');
	const [indexInputValue, setIndexInputValue] = useState('');
	const beforeAfterInputRef = useRef<HTMLInputElement>(null);
	const indexInputRef = useRef<HTMLInputElement>(null);
	const moving = useIsMoveInProgress();
	const { setError } = useAlerts();
	const [debouncedMessage, setDebouncedMessage] = useState('');
	const createNew = 'create_new';

	const [
		{
			data: {
				groups,
				groupsMap,
				groupsOutsideSets,
				variableSetsMap,
				variableSets,
				variablesMap
			}
		}
	] = useVariables({ initial: true, lazy: true });

	const variablesData = useVariablesData({ initial: true });

	const { variablesLocation, groupsLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const [{ loading: creatingGroup, error: errorCreatingGroup }, createGroup] = useCreateGroup();

	const [{ loading: creatingVariableSet, error: errorCreatingVariableSet }, createVariableSet] =
		useCreateVariableSet();

	const [
		{ loading: addingVariablesToGroup, error: errorAddingVariablesToGroup },
		addVariablesToGroup
	] = useAddVariablesToGroup();

	const [
		{ loading: movingVariablesBetweenGroups, error: errorMovingVariablesBetweenGroups },
		moveVariablesBetweenGroups
	] = useMoveVariablesBetweenGroups();

	const [
		{
			loading: movingVariablesOrGroupsToRootList,
			error: errorMovingVariablesOrGroupsToRootList
		},
		moveVariablesOrGroupsToRootList
	] = useMoveVariablesOrGroupsToRootList();

	const [
		{ loading: movingVariablesOrGroupsToSet, error: errorMovingVariablesOrGroupsToSet },
		moveVariablesOrGroupsToSet
	] = useMoveVariablesOrGroupsToSet();

	const { order } = variablesData;

	const mainLevelPosition = order.map((item, index) => ({
		value: Object.values(item)[0],
		position: index
	}));

	// CLOSE MODAL AFTER SELECTED VARIABLES WERE MOVED - NEW GROUP / NEW VARIABLE SET
	useCompletedAction(
		creatingGroup || creatingVariableSet,
		errorCreatingGroup || errorCreatingVariableSet,
		onClose
	);

	// CLOSE MODAL AFTER SELECTED VARIABLES WERE ADDED - EXISTING GROUP
	useCompletedAction(addingVariablesToGroup, errorAddingVariablesToGroup, onClose);

	// CLOSE MODAL AFTER SELECTED VARIABLES / GROUPS WERE MOVED - EXISTING GROUP / SET
	useCompletedAction(
		movingVariablesBetweenGroups || movingVariablesOrGroupsToSet,
		errorMovingVariablesBetweenGroups || errorMovingVariablesOrGroupsToSet,
		onClose
	);

	// CLOSE MODAL AFTER SELECTED VARIABLES / GORUPS WERE MOVED - ROOT LIST
	useCompletedAction(
		movingVariablesOrGroupsToRootList,
		errorMovingVariablesOrGroupsToRootList,
		onClose
	);

	useCompletedAction(movingVariablesOrGroupsToSet, errorMovingVariablesOrGroupsToSet, () => {
		onClose();
	});

	const [confirmAction, setConfirmAction] = useState(false);
	const [groupWithinSeries, setGroupWithinSeries] = useState(false);
	const [newSetLabel, setNewSetLabel] = useState('');
	const [newGroupLabel, setNewGroupLabel] = useState('');
	const [createNewGroup, setCreateNewGroup] = useState(true);
	const [createNewSet, setCreateNewSet] = useState(true);
	const [targetGroupName, setTargetGroupName] = useState<string | null>(null);
	const [targetSetName, setTargetSetName] = useState<string | null>(null);
	const [option, setOption] = useState<Options>(Options.group);

	const newGroupLabelTrimmed = newGroupLabel.trim();
	const isGroupLabelValid = newGroupLabelTrimmed.length > 0;

	const newSetLabelTrimmed = newSetLabel.trim();
	const isSetLabelValid = newSetLabelTrimmed.length > 0;

	const isGroupLabelUnique = !groups.some(group => group.groupLabel === newGroupLabelTrimmed);
	const isSetLabelUnique = !variableSets.some(set => set.setLabel === newSetLabelTrimmed);

	const canCreate = {
		group: isGroupLabelValid && isGroupLabelUnique,
		set: isSetLabelValid && isSetLabelUnique
	};

	const variableSetData = useMemo(() => {
		if (targetSetName) {
			const variableSetVariablesData = buildVariableSetVariablesData({
				setName: targetSetName,
				variablesData
			});
			return variableSetVariablesData;
		}
	}, [targetSetName]);

	const cannotMoveToMainLevel = useMemo(() => {
		if (mainSelectedVariables.length > 0) {
			return mainSelectedVariables.every(variableName => {
				if (variablesLocation[variableName]) {
					return false;
				}
				return true;
			});
		}
		if (mainSelectedGroups.length > 0) {
			return mainSelectedGroups.every(groupName => {
				if (groupsLocation[groupName]) {
					return false;
				}
				return true;
			});
		}
		return true;
	}, [mainSelectedVariables, mainSelectedGroups]);

	useEffect(() => {
		if (checkedState.checked.variables) {
			checkedState.checked.variables.forEach(variable => {
				const variableLocation = variablesLocation[variable];
				if (variableLocation) {
					if (
						(variableLocation.groupName &&
							!checkedState.checked.groups.includes(variableLocation.groupName)) ||
						!variableLocation.groupName
					) {
						if (!selectedVariables.includes(variable)) {
							setSelectedVariables(prevState => [...prevState, variable]);
						}
					} else if (
						variableLocation.groupName &&
						(groupWithinSeries || option === Options.group)
					) {
						if (!selectedVariables.includes(variable)) {
							setSelectedVariables(prevState => [...prevState, variable]);
						} else {
							setSelectedVariables(prevState => [
								...prevState.filter(v => v !== variable)
							]);
						}
					} else if (
						variableLocation.groupName &&
						option === Options.series &&
						checkedState.checked.groups.includes(variableLocation.groupName)
					) {
						setSelectedVariables(prevState => [
							...prevState.filter(v => v !== variable)
						]);
					}
					if (
						(option === Options.main &&
							variableLocation.groupName &&
							checkedState.checked.groups.includes(variableLocation.groupName)) ||
						(variableLocation.setName &&
							option === Options.main &&
							checkedState.checked.variableSets.includes(variableLocation.setName))
					) {
						setSelectedVariables(prevState => [
							...prevState.filter(v => v !== variable)
						]);
					}

					if (
						!variableLocation.setName ||
						(variableLocation.setName &&
							!checkedState.checked.variableSets.includes(variableLocation.setName))
					) {
						if (
							((variableLocation.groupName &&
								!checkedState.checked.groups.includes(
									variableLocation.groupName
								)) ||
								!variableLocation.groupName) &&
							!mainSelectedVariables.includes(variable)
						) {
							setMainSelectedVariables(prevState => [...prevState, variable]);
						}
					}
				} else {
					if (!selectedVariables.includes(variable)) {
						setSelectedVariables(prevState => [...prevState, variable]);
					}
					if (!mainSelectedVariables.includes(variable)) {
						setMainSelectedVariables(prevState => [...prevState, variable]);
					}
				}
			});
		}
		checkedState.checked.groups.forEach(group => {
			const groupLocation = groupsLocation[group];
			if (
				!selectedGroups.includes(group) &&
				groupsMap[group].variablesBelongingToGroup.length &&
				option !== Options.group
			) {
				setSelectedGroups(prevState => [...prevState, group]);
			} else if (option === Options.group || groupWithinSeries) {
				setSelectedGroups(prevState => [...prevState.filter(v => v !== group)]);
			}
			if (
				!mainSelectedGroups.includes(group) &&
				groupsMap[group].variablesBelongingToGroup.length &&
				((groupLocation?.setName &&
					!checkedState.checked.variableSets.includes(groupLocation.setName)) ||
					!groupLocation?.setName)
			) {
				setMainSelectedGroups(prevState => [...prevState, group]);
			}
		});
	}, [checkedState, option, groupWithinSeries]);

	function handleMoveVariablesOrGroupsToRootList() {
		const destinationByMoveOption = {
			[MOVE_OPTIONS.start]: 0,
			[MOVE_OPTIONS.end]: undefined,
			[MOVE_OPTIONS.before]: parseInt(beforeAfterInputValue) - 1,
			[MOVE_OPTIONS.after]: parseInt(beforeAfterInputValue) + 1,
			[MOVE_OPTIONS.index]: parseInt(indexInputValue) - 1
		};

		return moveVariablesOrGroupsToRootList(
			{
				variableNames: selectedVariables,
				groupNames: selectedGroups
			},
			destinationByMoveOption[moveOption]
		);
	}

	function getSelectGroupsItems() {
		const groupsToShow = groupsOutsideSets;
		const items: SelectItem[] = [];
		items.push({
			label: translate(dict => dict.variables.move.createNewGroup),
			value: createNew
		});
		if (variableSetData) {
			items.push(
				...Object.values(variableSetData.groupsMap).flatMap(group => {
					// Don't allow user to move variables to the same group
					if (
						checkedState.partOfSameGroup &&
						group.variablesBelongingToGroup.includes(checkedState.checked.variables[0])
					) {
						return [];
					}

					return {
						label: group.groupLabel,
						value: group.groupName
					};
				})
			);
			return items;
		}

		items.push(
			...groupsToShow.flatMap(group => {
				// Don't allow user to move variables to the same group
				if (
					checkedState.partOfSameGroup &&
					group.variablesBelongingToGroup.includes(checkedState.checked.variables[0])
				) {
					return [];
				}

				return {
					label: group.groupLabel,
					value: group.groupName
				};
			})
		);

		return items;
	}

	function getSelectSetsItems() {
		const setsToShow = variableSets;
		const items: SelectItem[] = [];
		items.push({
			label: translate(dict => dict.variables.move.createNewSeries),
			value: createNew
		});
		items.push(
			...setsToShow.flatMap(set => {
				return {
					label: set.setLabel,
					value: set.setName
				};
			})
		);

		return items;
	}

	function handleChangeOption(type: string) {
		setOption(type as Options);
		setNewGroupLabel('');
		setNewSetLabel('');
		setGroupWithinSeries(false);
		setTargetSetName(null);
		setTargetGroupName(null);
		setCreateNewGroup(true);
		setCreateNewSet(true);
	}

	/*
	 MOVE FUNCTIONS;
	*/

	function handleAddVariablesToGroup() {
		let filteredVariables = checkedState.checked.variables;
		const sourceGroupNames = filteredVariables.reduce((acc, variable) => {
			const groupName = variablesLocation[variable]?.groupName;
			if (groupName && !acc.includes(groupName)) {
				return [...acc, groupName];
			}
			return acc;
		}, [] as string[]);
		const hasMainLevelVariables = filteredVariables.some(
			variable => !variablesLocation[variable]?.groupName
		);

		// if moving to set, filter out file variables;
		if (targetSetName) {
			filteredVariables = checkedState.checked.variables.filter(
				variable => !disabledVariableTypesInSet.includes(variablesMap[variable].type)
			);
		}

		// ADD SELECTED VARIABLES TO NEW GROUP
		if (createNewGroup && canCreate.group) {
			// prevent moving group into new group in series
			// TODO: REDO
			// if (sourceGroupName && targetSetName) {
			// 	let filtered = false;
			// 	filteredVariables = filteredVariables.filter(variable => {
			// 		filtered = true;
			// 		return !groupsMap[sourceGroupName].variablesBelongingToGroup.includes(variable);
			// 	});
			// 	if (filtered) {
			// 		setError({
			// 			message: translate(dict => dict.variables.move.someElementsNotMoved)
			// 		});
			// 	}
			// }

			if (!filteredVariables.length) return;
			return createGroup(
				{ groupLabel: newGroupLabel },
				{
					variableNames: filteredVariables,
					setName: targetSetName ?? undefined
				}
			);
		}

		// ADD SELECTED VARIABLES TO EXISTING GROUP
		if (targetGroupName) {
			if (sourceGroupNames.length === 1 && !hasMainLevelVariables) {
				// prevent moving group into new group in series
				// TODO: REDO
				// filteredVariables = filteredVariables.filter(variable => {
				// 	return groupsMap[sourceGroupName].variablesBelongingToGroup.includes(variable);
				// });

				// if (!filteredVariables.length) {
				// 	return setError({
				// 		message: translate(dict => dict.variables.move.someElementsNotMoved)
				// 	});
				// }

				// ONLY FOR MOVING FROM ONE GROUP TO ANOTHER GROUP;
				return moveVariablesBetweenGroups({
					variableNames: filteredVariables,
					sourceGroupName: sourceGroupNames[0],
					destinationGroupName: targetGroupName,
					setName: targetSetName ?? undefined
				});
			}

			// MORE VERSATILE
			return addVariablesToGroup({
				groupName: targetGroupName,
				variableNames: filteredVariables,
				setName: targetSetName ?? undefined
			});
		}
	}

	function handleAddVariablesToSet() {
		// FILTER UNALLOWED VARIABLES/GROUPS
		const variables = selectedVariables.filter(
			variable => !disabledVariableTypesInSet.includes(variablesMap[variable].type)
		);
		const groups = selectedGroups.filter(
			group =>
				!groupsMap[group].variablesBelongingToGroup.some(variable =>
					disabledVariableTypesInSet.includes(variablesMap[variable].type)
				)
		);

		const variableSet: NewVariableSet = {
			setLabel: newSetLabelTrimmed
		};

		const group: NewGroup = {
			groupLabel: newGroupLabelTrimmed
		};

		// ADD SELECTED VARIABLES TO NEW SERIES
		if (createNewSet && canCreate.set) {
			return createVariableSet({
				variableSet,
				group,
				options: {
					variableNames: newGroupLabelTrimmed
						? checkedState.checked.variables.filter(
								variable =>
									!disabledVariableTypesInSet.includes(
										variablesMap[variable].type
									)
						  )
						: variables,
					groupNames: newGroupLabelTrimmed ? [] : groups
				}
			});
		}
		if (targetSetName) {
			return moveVariablesOrGroupsToSet(
				{
					variableNames: variables,
					groupNames: groups
				},
				targetSetName,
				targetGroupName ?? undefined
			);
		}
	}

	const createNewGroupInSeries =
		!canCreate.set && createNewGroup && canCreate.group && groupWithinSeries;
	const createNewGroupInMain = !canCreate.set && createNewGroup && canCreate.group;

	// TOAST MESSAGE ON MOVE STATE
	const hasEmptyGroups = () => {
		const groups = checkedState.checked.groups;
		return (
			groups.length &&
			groups.some(group => groupsMap[group].variablesBelongingToGroup.length === 0)
		);
	};

	const hasForbiddenVariables = () => {
		const variablesContainForbiddenType = (variables: string[]) =>
			variables.some(variable =>
				disabledVariableTypesInSet.includes(variablesMap[variable].type)
			);

		const groupVariables = selectedGroups
			.map(group => groupsMap[group].variablesBelongingToGroup)
			.flat();
		return variablesContainForbiddenType([...selectedVariables, ...groupVariables]);
	};

	// Has movable group/variable
	const hasMovableVariables = () => {
		const getMovableVariables = (variables: string[]) => {
			return (
				variables.length &&
				variables.some(
					variable => !disabledVariableTypesInSet.includes(variablesMap[variable].type)
				)
			);
		};

		const hasMovableVariables = getMovableVariables(selectedVariables);
		const hasMovableGroups = getMovableVariables(
			selectedGroups.map(group => groupsMap[group].variablesBelongingToGroup).flat()
		);

		return hasMovableGroups || hasMovableVariables;
	};

	function handleMove() {
		if (moving || primaryButtonLoading) return () => undefined;

		const hasVariableInSeries = selectedVariables.some(
			variable => !!variablesLocation[variable]?.setName
		);

		const hasGroupInSeries = selectedGroups.some(group => !!groupsLocation[group]?.setName);

		const variablesFromSet = selectedVariables.filter(
			variable => !!variablesLocation[variable]?.setName
		);
		const areAllVariablesFromSameSet = variablesFromSet.length
			? variablesFromSet.every(
					variable => variablesLocation[variable]?.setName === targetSetName
			  )
			: false;

		const shouldConfirmPrompt = !areAllVariablesFromSameSet && !!targetSetName;

		// MOVING TO GROUP
		if (option === Options.group) {
			if (hasGroupInSeries || hasVariableInSeries || targetSetName) {
				return () => setConfirmAction(true);
			}
			return handleAddVariablesToGroup;
		}

		// MOVING TO SERIES
		if (option === Options.series) {
			// CANNOT MOVE => return;
			const canMove = hasMovableVariables();
			const showEmptyMsg = hasEmptyGroups();
			const showTypeNotAllowedMsg = hasForbiddenVariables();

			if (!canMove) {
				if (showTypeNotAllowedMsg && showEmptyMsg) {
					return () =>
						setError({
							message: translate(
								dict => dict.variables.move.emptyGroupAndForbiddenType
							)
						});
				}

				if (showTypeNotAllowedMsg) {
					return () =>
						setError({
							message: translate(dict => dict.variables.forbiddenTypeInSeries)
						});
				}

				if (showEmptyMsg) {
					return () =>
						setError({
							message: translate(dict => dict.variables.move.emptyGroup)
						});
				}
			} else {
				// CAN MOVE => still show debounced toast;
				if (showEmptyMsg) {
					if (showTypeNotAllowedMsg) {
						return () => {
							setDebouncedMessage(
								translate(dict => dict.variables.move.emptyGroupAndForbiddenType)
							);
							setConfirmAction(true); // This shows second modal and switches function based on current moving state;
						};
					} else {
						return () => {
							setDebouncedMessage(translate(dict => dict.variables.move.emptyGroup));
							setConfirmAction(true); // This shows second modal and switches function based on current moving state;
						};
					}
				} else if (showTypeNotAllowedMsg) {
					return () => {
						setDebouncedMessage(
							translate(dict => dict.variables.forbiddenTypeInSeries)
						);
						setConfirmAction(true); // This shows second modal and switches function based on current moving state;
					};
				}
			}
			if (shouldConfirmPrompt) {
				return () => setConfirmAction(true);
			}
			if (createNewGroupInSeries || groupWithinSeries) {
				return handleAddVariablesToGroup;
			}
			return handleAddVariablesToSet;
		}
		/*
		 MAIN LEVEL
		*/
		// If includes at least a variable in series, should prompt;
		if (hasGroupInSeries || hasVariableInSeries || targetSetName) {
			return () => setConfirmAction(true);
		}
		return handleMoveVariablesOrGroupsToRootList;
	}

	function onConfirmMove() {
		if (option === Options.main) {
			handleMoveVariablesOrGroupsToRootList();
		} else if (option === Options.series) {
			if (createNewGroupInSeries || groupWithinSeries) {
				handleAddVariablesToGroup();
			} else {
				handleAddVariablesToSet();
			}
		} else if (option === Options.group && (createNewGroupInMain || targetGroupName)) {
			handleAddVariablesToGroup();
		}
	}

	const primaryButtonLoading =
		creatingGroup ||
		addingVariablesToGroup ||
		movingVariablesBetweenGroups ||
		movingVariablesOrGroupsToSet ||
		movingVariablesOrGroupsToRootList ||
		creatingVariableSet;

	const primaryButtonDisabled =
		(createNewGroup &&
			(!isGroupLabelValid || !isGroupLabelUnique) &&
			option === Options.group) ||
		(createNewSet && (!isSetLabelValid || !isSetLabelUnique) && option === Options.series) ||
		(!createNewGroup && !targetGroupName) ||
		(!createNewSet && !targetSetName) ||
		(groupWithinSeries && createNewGroup && (!isGroupLabelValid || !isGroupLabelUnique));

	const moveOptionsSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.beginning),
			value: MOVE_OPTIONS.start
		},
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.end),
			value: MOVE_OPTIONS.end
		},
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.before),
			value: MOVE_OPTIONS.before
		},
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.after),
			value: MOVE_OPTIONS.after
		},
		{
			label: translate(dict => dict.variablesPage.moveVariablesModal.atPosition),
			value: MOVE_OPTIONS.index
		}
	];

	/**
	 * Sets value only within min-max range
	 */
	function setIndexInputValueSafe(value: string) {
		// allow numbers only
		value = value.replace(/[^1-9]+/g, '');

		const numberValue = Number(value);

		if (numberValue > mainLevelPosition.length + 1) return;

		setIndexInputValue(value);
	}

	function focusNextRequiredField(moveOption: MOVE_OPTIONS) {
		const inputRefByMoveOption = {
			[MOVE_OPTIONS.start]: null,
			[MOVE_OPTIONS.end]: null,
			[MOVE_OPTIONS.before]: beforeAfterInputRef,
			[MOVE_OPTIONS.after]: beforeAfterInputRef,
			[MOVE_OPTIONS.index]: indexInputRef
		};

		setTimeout(() => inputRefByMoveOption[moveOption]?.current?.focus());
	}

	const beforeValuesSelectItems: SelectItem[] = useMemo(() => {
		const items: SelectItem[] = [];

		mainLevelPosition.forEach(variable => {
			if (checkedState.checked.variables.includes(variable.value)) return;

			if (checkedState.checked.groups.includes(variable.value)) return;

			if (checkedState.checked.variableSets.includes(variable.value)) return;

			const item: SelectItem = {
				label:
					variablesMap[variable.value]?.label ||
					groupsMap[variable.value]?.groupLabel ||
					variableSetsMap[variable.value]?.setLabel,
				value: variable.position.toString()
			};

			items.push(item);
		});

		return items;
	}, [mainLevelPosition]);

	const selectItems: Option[] = [
		{ label: translate(dict => dict.variables.move.group), value: Options.group },
		{ label: translate(dict => dict.variables.move.series), value: Options.series },
		...(!cannotMoveToMainLevel
			? [{ label: translate(dict => dict.variables.move.mainLevel), value: Options.main }]
			: [])
	];

	return (
		<>
			<Modal
				size={s => s.m}
				title={translate(dict => dict.variables.move.title)}
				primary={{
					label: translate(dict => dict.buttons.apply),
					loading: primaryButtonLoading,
					disabled: primaryButtonDisabled,
					onClick: handleMove()
				}}
				neutral={{
					label: translate(dict => dict.buttons.cancel),
					onClick: onClose
				}}
				onClose={onClose}
				visible={!confirmAction}
				close
			>
				<Flex style={{ minHeight: '22rem' }} column>
					<RadioGroupUncontrolled
						dataTestId="radio-group-move-target"
						name={translate(dict => dict.variablesPage.moveVariablesModal.destination)}
						options={selectItems}
						value={option}
						onChange={handleChangeOption}
					/>

					<Spacer size={s => s.s} />

					{option === Options.series && (
						<>
							<CreatableSelect
								menuTestId="dropdown-menu-series"
								dropdownIconTestId="dropdown-toggle-series"
								valueContainerIconTestId="dropdown-value-container-series"
								value={
									createNewSet
										? {
												label: translate(
													dict => dict.variables.move.createNewSeries
												),
												value: createNew
										  }
										: targetSetName
										? {
												label: variableSetsMap[targetSetName].setLabel,
												value: variableSetsMap[targetSetName].setName
										  }
										: null
								}
								disabled={false}
								placeholder={translate(
									dict => dict.variables.move.createNewSeriesPlaceholder
								)}
								items={getSelectSetsItems()}
								onValueSelected={item => {
									if (item === createNew) {
										setNewSetLabel('');
										setCreateNewSet(true);
										setTargetSetName(null);
										setGroupWithinSeries(false);
									} else {
										setCreateNewSet(false);
										if (item) setTargetSetName(item);
									}
								}}
								noOptionsMessage={translate(dict => dict.variables.move.noSeries)}
								scrollIntoView
								type={t => t.Search}
								onClear={() => {
									setTargetSetName(null);
								}}
							/>
							<Spacer size={s => s.s} />
						</>
					)}

					{createNewSet && option === Options.series && (
						<Input
							type={InputType.Text}
							value={newSetLabel}
							label={translate(dict => dict.variables.move.newSeriesLabel)}
							placeholder={translate(
								dict => dict.variables.move.newSeriesPlaceholder
							)}
							error={
								!isSetLabelUnique
									? translate(dict => dict.variables.move.error)
									: undefined
							}
							onChange={e => setNewSetLabel(e.target.value)}
							onSubmit={handleMove()}
							autoFocus
						/>
					)}

					{(targetSetName || newSetLabelTrimmed) && (
						<>
							<Spacer size={s => s.s} />
							<Checkbox
								dataTestId="group-in-series-checkbox"
								label={translate(dict => dict.variables.move.groupWithinSeries)}
								checked={groupWithinSeries}
								onClick={() => setGroupWithinSeries(s => !s)}
							/>
							<Spacer size={s => s.s} />
						</>
					)}
					{(option === Options.group || (groupWithinSeries && targetSetName)) && (
						<>
							<CreatableSelect
								valueContainerIconTestId="dropdown-value-container-group-series"
								value={
									createNewGroup
										? {
												label: translate(
													dict => dict.variables.move.createNewGroup
												),
												value: createNew
										  }
										: targetGroupName
										? {
												label: groupsMap[targetGroupName].groupLabel,
												value: groupsMap[targetGroupName].groupName
										  }
										: null
								}
								disabled={false}
								placeholder={translate(
									dict => dict.variables.move.createNewGroupPlaceholder
								)}
								items={getSelectGroupsItems()}
								onValueSelected={item => {
									if (item === createNew) {
										setNewSetLabel('');
										setCreateNewGroup(true);
										setTargetGroupName(null);
										if (!targetSetName || (createNewGroup && !newSetLabel)) {
											setGroupWithinSeries(false);
										}
									} else {
										setCreateNewGroup(false);
										if (item) setTargetGroupName(item);
									}
								}}
								noOptionsMessage={translate(dict => dict.variables.move.noGroups)}
								scrollIntoView
								type={t => t.Search}
								onClear={() => {
									setTargetGroupName(null);
								}}
							/>
							<Spacer size={s => s.s} />
						</>
					)}
					{((createNewGroup && option === Options.group) ||
						(groupWithinSeries && createNewGroup)) && (
						<Input
							type={InputType.Text}
							value={newGroupLabel}
							label={translate(dict => dict.variables.move.newGroupLabel)}
							placeholder={translate(dict => dict.variables.move.newGroupPlaceholder)}
							error={
								!isGroupLabelUnique
									? translate(dict => dict.variables.move.error)
									: undefined
							}
							onChange={e => setNewGroupLabel(e.target.value)}
							onSubmit={handleMove()}
							autoFocus
						/>
					)}
					{option === Options.main && (
						<>
							<CreatableSelect
								dropdownIconTestId="dropdown-icon-container-mainlevel"
								label={translate(dict => dict.variables.move.option)}
								value={moveOptionsSelectItems.find(
									selectItem => selectItem.value === moveOption
								)}
								items={moveOptionsSelectItems}
								onValueSelected={value => {
									if (!value) return;

									const newMoveOption = value as MOVE_OPTIONS;

									setMoveOption(newMoveOption);
									focusNextRequiredField(newMoveOption);
								}}
								canClear={false}
							/>

							<Spacer size={s => s.s} />

							{[MOVE_OPTIONS.before, MOVE_OPTIONS.after].includes(moveOption) && (
								<CreatableSelect
									_ref={beforeAfterInputRef}
									label={translate(dict => dict.variables.move.selectDataLabel)}
									placeholder={translate(
										dict => dict.variables.move.selectDataPlaceholder
									)}
									value={beforeValuesSelectItems.find(
										selectItem => selectItem.value === beforeAfterInputValue
									)}
									items={beforeValuesSelectItems}
									onValueSelected={categoryId => {
										if (!categoryId) return;

										setBeforeAfterInputValue(categoryId);
									}}
									canClear={false}
								/>
							)}

							{[MOVE_OPTIONS.index].includes(moveOption) && (
								<Input
									ref={indexInputRef}
									type={InputType.Number}
									label={translate(dict => dict.variables.move.position)}
									placeholder={translate(dict => dict.variables.move.position)}
									hint={`${translate(
										dict => dict.variablesPage.moveVariablesModal.positionInList
									)}
									${mainLevelPosition.length + 1}
								`}
									value={indexInputValue}
									minValue={1}
									maxValue={mainLevelPosition.length + 1}
									disabled={moveOption !== MOVE_OPTIONS.index}
									onChange={e => setIndexInputValueSafe(e.target.value)}
								/>
							)}
						</>
					)}
				</Flex>
			</Modal>
			{confirmAction && (
				<ConfirmMoveVariablesToSetModal
					debouncedMessage={debouncedMessage}
					onConfirm={onConfirmMove}
					direction={
						selectItems.find(opt => opt.value === option)?.label as
							| 'Main Level'
							| 'Series'
					}
					onClose={onClose}
				/>
			)}
		</>
	);
}
