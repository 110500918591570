import {
	GetLogisticRegressionOutputV2,
	GetLogisticRegressionResponseV2,
	GroupedLogisticRegressionDataV2,
	GroupedLogisticRegressionResultV2,
	LogisticRegressionCoefficients,
	UngroupedLogisticRegressionDataV2
} from 'api/data/analyses';

// type guards
function isGroupedLogisticRegressionData(
	data: GetLogisticRegressionResponseV2['data']['result']
): data is GroupedLogisticRegressionDataV2[] {
	return 'length' in data;
}

export function parseLogisticRegressionResponse(
	res: GetLogisticRegressionResponseV2
): GetLogisticRegressionOutputV2 {
	if (isGroupedLogisticRegressionData(res.data.result)) {
		const result: GetLogisticRegressionOutputV2 = { data: [] };
		const groupedData = res.data.result;

		result.data = groupedData.reduce<GroupedLogisticRegressionResultV2[]>((array, data) => {
			return [
				...array,
				{
					logisticRegression: {
						coefficients: parseCoefficients(data.values.coefficients),
						xValues: data.values.xValues,
						yValues: data.values.yValues,
						error: data.values.errors?.[0]
					},
					group: {
						groupValue: data.groupIdentifiers?.[0].value,
						groupVariable: data.groupIdentifiers?.[0].variable.name
					}
				}
			];
		}, []);

		return result;
	}
	const ungroupedData = res.data.result;

	return {
		data: {
			coefficients: parseCoefficients(ungroupedData.coefficients),
			xValues: ungroupedData.xValues,
			yValues: ungroupedData.yValues,
			error: ungroupedData.errors?.[0]
		}
	};
}

function parseCoefficients(
	coefficients: UngroupedLogisticRegressionDataV2['coefficients']
): LogisticRegressionCoefficients | null {
	if (!coefficients) return null;
	return coefficients.map(coef => ({
		estimate: coef.estimate,
		type: coef.type,
		lowerCI: coef.lowerCI === null ? 'N/A' : coef.lowerCI.toString(),
		pValue: coef.pValue === null ? 'N/A' : coef.pValue.toString(),
		standardError: coef.standardError === null ? 'N/A' : coef.standardError.toString(),
		statistic: coef.statistic === null ? 'N/A' : coef.statistic.toString(),
		upperCI: coef.upperCI === null ? 'N/A' : coef.upperCI.toString(),
		variableName: coef.variable?.name
	}));
}
