import { useDependencies, useForms, useStatuses, useVariablesData } from 'hooks/store';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DependenciesData } from 'store/data/dependencies';
import { Form } from 'store/data/forms';
import { VariablesData } from 'store/data/variables';

type Args = {
	dependenciesData: DependenciesData;
	hasStatuses: boolean;
	forms: Form[];
	variablesData: VariablesData;
};

export const shouldRouteToV1_5 = (
	{ dependenciesData, hasStatuses, forms, variablesData }: Args,
	entryV1_5Release:
		| 'variablesAndGroups'
		| 'dependenciesVariablesAndGropus'
		| 'formsDependenciesVariablesAndGroups'
		| 'seriesFormsDependenciesVariablesAndGroups'
		| 'disabled'
		| 'fullyEnabled'
) => {
	if (entryV1_5Release === 'disabled') {
		return false;
	}
	if (entryV1_5Release === 'fullyEnabled') {
		return true;
	}

	const hasDependencies = dependenciesData.active && dependenciesData.dependencies.length > 0;
	const hasActiveForms = forms.some(form => form.active);
	const hasSeries = Object.entries(variablesData.variableSetsMap).length > 0;
	const hasStatus = hasStatuses;

	switch (entryV1_5Release) {
		case 'variablesAndGroups':
			return !hasDependencies && !hasActiveForms && !hasSeries && !hasStatus;
		case 'dependenciesVariablesAndGropus':
			return !hasActiveForms && !hasSeries && !hasStatus;
		case 'formsDependenciesVariablesAndGroups':
			return !hasSeries && !hasStatus;
		case 'seriesFormsDependenciesVariablesAndGroups':
			return !hasStatus;
		default:
			console.error('Invalid entryV1_5Release value:', entryV1_5Release);
			return false;
	}
};

export const useShouldUseV1_5Routing = () => {
	const { entryV15Release } = useFlags();

	// Adding lazy: true because we need to fetch variables first
	// In the context we are using this hook, variables and dependencies are fetched eventually which then means we get
	// the updated data in the end.
	const [{ data: dependenciesData }] = useDependencies({ lazy: true });
	const [{ data: statusesData }] = useStatuses();
	const [{ data: formsData }] = useForms();

	// honestly dont know what 'initial' is supposed to do here, just keeping it as it was like this in the Entries component.
	const variablesData = useVariablesData({ initial: true });

	if (!dependenciesData || !statusesData || !formsData || !variablesData) {
		return false;
	}

	return shouldRouteToV1_5(
		{
			dependenciesData,
			hasStatuses: statusesData.statuses.length > 0,
			forms: formsData,
			variablesData
		},
		entryV15Release
	);
};
