import format from 'date-fns/format';

import {
	AggregationRule,
	AggregationRuleType,
	ApiTimeDurationFormat,
	ApiVariable,
	ApiVariablesData,
	ApiVariableSet,
	Group,
	PartialApiVariable,
	PartialApiVariableSet,
	PartialVariableCategory,
	PartialVariableSet,
	TimeDurationFormat,
	PROMSystemGenerated,
	ValidationCases,
	Variable,
	VariableCategory,
	VariableSet
} from 'api/data/variables';
import { VariableSubType } from 'api/data/variables/statics';
import {
	DATE_FORMAT,
	dateTimeFormatMap,
	EDITABLE_DATA_TYPES_OPTIONS,
	VARIABLE_DATA_DEFAULT_VALUES
} from 'consts';
import { ORDERED_TIME_DURATION_KEYS } from 'timeDurationConsts';
import { arrayUtils } from 'helpers/arrays';
import { decodeURIComponentSafe, translateVariableTypeMap } from 'helpers/generic';
import { numberToFixed } from 'helpers/numbers';
import { objectDifference } from 'helpers/objects';
import { decodeString, hasMatches } from 'helpers/strings';
import { TranslateFunction } from 'hooks/store/ui/useTranslation';
import { cloneDeep, isEqual, values } from 'lodash';
import { DateTimeFormat } from 'store/account/subscription';
import { Dependency, DependencyType } from 'store/data/dependencies';
import {
	ActionTypes,
	GroupData,
	GroupLocation,
	GroupsMap,
	StoreVariablesData,
	VariableLocation,
	VariablesData,
	VariablesDataArray,
	VariablesDataArrayItem,
	VariablesDataLocation,
	VariableSetData,
	VariableSetsMap,
	VariableSetsState,
	VariablesMap,
	VariablesOrder,
	VariablesOrderItem,
	VariablesRichData
} from 'store/data/variables';
import {
	BooleanMap,
	GenericMap,
	RequireAtLeastOne,
	RequireOnlyOne,
	SelectGroup,
	SelectItem,
	SelectItemOrGroup,
	StringMap
} from 'types/index';
import { getTimeDurationStringFromMicroseconds, parseTimeDurationEntryCell } from 'helpers/entries';
import {
	EntryVariableType,
	FixedCategories,
	VariableType,
	ConditionOperator
} from 'types/data/variables/constants';

function generate() {
	return `${Math.random().toString(4)}-${Math.random().toString(4)}-${Math.random().toString(
		4
	)}-${Math.random().toString(4)}`;
}

export function getInitialVariableData(variable: Variable | null): Variable {
	if (variable) {
		return {
			...variable,
			validationCases: {
				minValue: variable.validationCases?.minValue?.toString() || '',
				maxValue: variable.validationCases?.maxValue?.toString() || ''
			}
		};
	}

	return VARIABLE_DATA_DEFAULT_VALUES;
}

/**
 * Returns true if the given type can be used with entry type - calculated
 * @param type
 * @returns
 */
export function isCalculatedType(type: VariableType) {
	const calculatedTypes = [
		VariableType.Category,
		VariableType.Integer,
		VariableType.Float,
		VariableType.Date,
		VariableType.DateTime
	];
	return calculatedTypes.includes(type);
}

/**
 * Returns true if the given type can be change by the user when editing a variable
 * @param type
 * @returns
 */
export const isEditableType = (type: VariableType) =>
	EDITABLE_DATA_TYPES_OPTIONS.filter(dataType => dataType.value === type).length > 0;

export function prepareApiVariable(variable: Variable): ApiVariable {
	const {
		name,
		categories,
		description,
		label,
		type,
		fixedCategories,
		optimizeForManyValues,
		obligatory,
		entryType,
		cases,
		casesVariableAliases,
		subType,
		personalData,
		uniquenessType,
		visiblePrecision,
		durationFormat,
		specialization
	} = variable;

	const parsedObligatoryAndPersonalData = {
		obligatory: personalData ? false : !!obligatory,
		personalData: obligatory ? false : personalData
	};

	const DATE_FORMAT_FOR_SAVE = '%d/%m/%Y';

	const validationCases = getValidationCasesForApi(variable);

	const apiVariable: ApiVariable = {
		variableName: name,
		variableLabel: label,
		variableType: type,
		subType,
		description: description,
		fixedCategories: fixedCategories ? FixedCategories.Yes : FixedCategories.No,
		optimizeForManyValues: !!optimizeForManyValues,
		dateFormat: DATE_FORMAT_FOR_SAVE, // TODO: later we will want to have custom date formats
		entryType: entryType,
		...(visiblePrecision && {
			visiblePrecision
		}),
		...(fixedCategories && {
			allowedCategories: categories
		}),
		...(cases &&
			cases.length !== 0 &&
			entryType === EntryVariableType.Calculated && {
				cases
			}),
		...(casesVariableAliases &&
			Object.keys(casesVariableAliases).length !== 0 &&
			entryType === EntryVariableType.Calculated && {
				casesVariableAliases
			}),
		...(validationCases.length && {
			validationCases: validationCases as [] // TODO: implement type for `validationCases`
		}),
		...(durationFormat && {
			durationFormat: encodeFormat(durationFormat)
		}),
		...parsedObligatoryAndPersonalData,
		...(uniquenessType && { uniquenessType }),
		...(specialization && { specialization })
	};

	return apiVariable;
}
/**
 *
 * @param format
 * @returns ['hours', 'minutes' ..]
 */
export function decodeFormat(format: ApiTimeDurationFormat): TimeDurationFormat {
	const startIndex = ORDERED_TIME_DURATION_KEYS.findIndex(key => key === format.maxTimeUnit);
	const endIndex = ORDERED_TIME_DURATION_KEYS.findIndex(key => key === format.minTimeUnit);
	return ORDERED_TIME_DURATION_KEYS.slice(startIndex, endIndex + 1);
}

function getValidationCasesForApi(variable: Variable) {
	const { validationCases, label, type, name } = variable;

	const parsedValidationCases = [];

	if (validationCases) {
		if (validationCases.minValue !== undefined && validationCases.minValue !== '') {
			parsedValidationCases.push({
				validationExpression: {
					operator: ConditionOperator.Or,
					operands: [
						{
							operator: ConditionOperator.Equals,
							operands: [
								{
									operator: ConditionOperator.NullValue,
									operands: ['null']
								},
								{
									operator: ConditionOperator.Variable,
									operands: [name]
								}
							]
						},
						{
							operator: ConditionOperator.GreaterThanOrEqual,
							operands: [
								{
									operator: ConditionOperator.Variable,
									operands: [name]
								},
								getValidationCasesType(type, validationCases.minValue)
							]
						}
					]
				},
				vaildationFailedMessage:
					label + ' must be greater than or equal to ' + validationCases.minValue + '.'
			});
		}

		if (validationCases.maxValue !== undefined && validationCases.maxValue !== '') {
			parsedValidationCases.push({
				validationExpression: {
					operator: ConditionOperator.Or,
					operands: [
						{
							operator: ConditionOperator.Equals,
							operands: [
								{
									operator: ConditionOperator.NullValue,
									operands: ['null']
								},
								{
									operator: ConditionOperator.Variable,
									operands: [name]
								}
							]
						},
						{
							operator: ConditionOperator.LessThanOrEqual,
							operands: [
								{
									operator: ConditionOperator.Variable,
									operands: [name]
								},
								getValidationCasesType(type, validationCases.maxValue)
							]
						}
					]
				},
				vaildationFailedMessage:
					label + ' must be lower than or equal to ' + validationCases.maxValue + '.'
			});
		}
	}

	return parsedValidationCases;
}

const getValidationCasesType = (type: VariableType, value: string) => {
	switch (type) {
		case VariableType.Integer:
			return {
				operator: 'op1_integer_val',
				operands: [parseInt(value, 10)]
			};

		case VariableType.Float:
			return {
				operator: 'op1_float_val',
				operands: [parseFloat(value)]
			};

		case VariableType.Date:
			return {
				operator: 'op1_date_val',
				operands: [value]
			};

		case VariableType.DateTime:
			return {
				operator: 'op1_datetime_val',
				operands: [value]
			};

		default:
			break;
	}
};

export function preparePartialApiVariable(
	initialVariable: Variable,
	variable: Variable
): PartialApiVariable {
	const updatedFields = objectDifference(initialVariable, variable);

	const {
		description,
		label,
		type,
		targetType,
		fixedCategories,
		optimizeForManyValues,
		obligatory,
		entryType,
		cases,
		casesVariableAliases,
		subType,
		personalData,
		validationRange,
		uniquenessType,
		visiblePrecision,
		durationFormat,
		specialization
	} = updatedFields;

	const initialValidationCases = getValidationCasesForApi(initialVariable);
	const validationCases = getValidationCasesForApi(variable);

	const validationCasesChanged = !isEqual(initialValidationCases, validationCases);

	const changedEntryTypeFromCalculatedToEntry =
		entryType !== undefined && entryType === EntryVariableType.Entry;

	const apiVariable: PartialApiVariable = {
		variableName: variable.name,
		...(label !== undefined && { variableLabel: label }),
		...(type !== undefined && { variableType: type }),
		...(targetType !== undefined &&
			targetType === null && {
				variableType: variable.type,
				variableTypeTarget: null
			}),
		...(description !== undefined && { description }),
		...(subType !== undefined && { subType }),
		...(optimizeForManyValues !== undefined && {
			optimizeForManyValues: !!optimizeForManyValues
		}),
		...((personalData !== undefined || obligatory !== undefined) && {
			obligatory: personalData ? false : !!obligatory,
			personalData: obligatory ? false : !!personalData
		}),
		...(entryType !== undefined && { entryType: entryType }),
		...(fixedCategories !== undefined && {
			fixedCategories: fixedCategories ? FixedCategories.Yes : FixedCategories.No
		}),
		...(cases !== undefined &&
			cases.length &&
			variable.entryType === EntryVariableType.Calculated && {
				cases
			}),
		// REMOVES ALL CALCULATION CASES IF NOT `calculated` ENTRY TYPE ANYMORE
		...(changedEntryTypeFromCalculatedToEntry && { cases: [] }),
		...(casesVariableAliases !== undefined &&
			Object.keys(casesVariableAliases).length &&
			variable.entryType === EntryVariableType.Calculated && {
				casesVariableAliases
			}),
		// REMOVES ALL CALCULATION CASES VARIABLE ALIASES IF NOT `calculated` ENTRY TYPE ANYMORE
		...(changedEntryTypeFromCalculatedToEntry && { casesVariableAliases: {} }),
		...(validationCasesChanged && {
			validationCases: validationCases as [] // TODO: implement type for `validationCases`
		}),
		// REMOVE VALIDATION RANGE IF FLAG WAS CHANGED FROM `true` TO `false`
		...(validationRange !== undefined &&
			!validationRange && {
				validationCases: []
			}),
		...(durationFormat && { durationFormat: encodeFormat(durationFormat) }),
		...(uniquenessType !== undefined && { uniquenessType }),
		...(visiblePrecision !== undefined && { visiblePrecision: visiblePrecision }),
		...(specialization && { specialization })
	};

	return apiVariable;
}

export function parseApiVariable(apiVariable: ApiVariable): Variable {
	const {
		variableName,
		variableLabel,
		description,
		variableType,
		variableTypeTarget,
		subType,
		entryType,
		fixedCategories,
		allowedCategories,
		categories,
		optimizeForManyValues,
		validationCases,
		cases,

		casesVariableAliases,
		personalData,
		obligatory,
		uniquenessType,
		visiblePrecision,
		durationFormat,
		specialization
	} = apiVariable;

	const computedCategories: VariableCategory[] = [];

	if (allowedCategories && allowedCategories.length) {
		const oldStructure = typeof allowedCategories[0] === 'string';

		/**
		 * Can happen when importing old templates created before the category-value-management feature
		 */
		if (oldStructure) {
			const categoryValues = (allowedCategories as unknown as string[]).map(value =>
				initVariableCategory({ value })
			);

			computedCategories.push(...categoryValues);
		} else {
			computedCategories.push(...allowedCategories);
		}
	} else if (categories && categories.length) {
		const categoryValues = categories.map(value => initVariableCategory({ value }));

		computedCategories.push(...categoryValues);
	}

	const finalCategories = computedCategories.map(c => {
		c.value = decodeURIComponentSafe(c.value);
		c.label = decodeURIComponentSafe(c.label);

		// returning without destructuring causes hard to trace error in jest:
		// TypeError: Cannot assign to read only property 'value' of object '#<Object>'
		return { ...c };
	});

	const isUnique = variableType === VariableType.Unique;

	const variable: Variable = {
		name: variableName,
		label: decodeString(variableLabel),
		description: decodeString(description),
		type: variableType,
		...(variableTypeTarget && {
			targetType: variableTypeTarget
		}),
		subType: subType ?? VariableSubType.Days, // TODO: needs to be optional in the future
		entryType: entryType ?? EntryVariableType.Entry,
		fixedCategories: fixedCategories ? fixedCategories === FixedCategories.Yes : false,
		categories: finalCategories,
		obligatory: obligatory ?? false,
		personalData: personalData ?? false,
		optimizeForManyValues: optimizeForManyValues ?? false,
		validationRange: !!(validationCases && getInitialValidationCases(validationCases)),
		validationCases: getInitialValidationCases(validationCases),
		cases: cases ?? [],
		...(casesVariableAliases && { casesVariableAliases }),
		visiblePrecision,
		...(durationFormat &&
			variableType === VariableType.TimeDuration && {
				durationFormat: decodeFormat(durationFormat)
			}),
		specialization,
		...(isUnique && { uniquenessType })
	};

	return variable;
}

export function encodeFormat(format: TimeDurationFormat): ApiTimeDurationFormat {
	const [maxTimeUnit, minTimeUnit] = [format[0], format[format.length - 1]];
	return {
		minTimeUnit: minTimeUnit,
		maxTimeUnit: maxTimeUnit
	};
}

// FIXME: refactor with proper types
function getInitialValidationCases(validationCases: any): ValidationCases | null {
	if (validationCases && validationCases.length !== 0) {
		if (
			validationCases[0] &&
			validationCases[0].validationExpression.operator === ConditionOperator.Or
		) {
			return {
				minValue:
					validationCases[0] &&
					validationCases[0].validationExpression.operands[1].operator ===
						ConditionOperator.GreaterThanOrEqual
						? validationCases[0].validationExpression.operands[1].operands[1].operands[0].toString()
						: '',
				maxValue: validationCases[1]
					? validationCases[1].validationExpression.operands[1].operands[1].operands[0].toString()
					: validationCases[0] &&
					  validationCases[0].validationExpression.operands[1].operator ===
							ConditionOperator.LessThanOrEqual
					? validationCases[0].validationExpression.operands[1].operands[1].operands[0].toString()
					: ''
			};
		}
		//  TODO: This sould be removed after - [OLD COMMENT by @Tudor Golcea]
		return {
			minValue: validationCases[0]
				? validationCases[0].validationExpression.operands[1].operands[0].toString()
				: '',
			maxValue: validationCases[1]
				? validationCases[1].validationExpression.operands[1].operands[0].toString()
				: ''
		};
	}

	return null;
}

export function parseApiGroup(apiGroup: Group): Group {
	const { groupName, groupLabel, variablesBelongingToGroup } = apiGroup;

	const group: Group = {
		groupName,
		groupLabel: decodeString(groupLabel),
		variablesBelongingToGroup
	};

	return group;
}

export function parseApiVariableSet(apiVariableSet: ApiVariableSet): VariableSet {
	const {
		setName,
		setLabel,
		setOrder = [],
		identifyingVariable,
		aggregationRules: apiAggregationRules
	} = apiVariableSet;

	const aggregationRules = parseApiAggregationRules(apiAggregationRules);

	const variableSet: VariableSet = {
		setName,
		setLabel,
		setOrder,
		identifier: {
			variableName: identifyingVariable
		},
		aggregationRules
	};

	return variableSet;
}

export function parseApiAggregationRule(apiAggregationRule: AggregationRule): AggregationRule {
	const { name, aggregator, rule } = apiAggregationRule;

	const aggregationRule: AggregationRule = {
		name,
		aggregator,
		rule
	};

	return aggregationRule;
}

export function parseApiAggregationRules(
	apiAggregationRules: AggregationRule[]
): AggregationRule[] {
	return apiAggregationRules.map(apiAggregationRule =>
		parseApiAggregationRule(apiAggregationRule)
	);
}

export function orderContainer(order: VariablesOrder) {
	const newOrder = cloneDeep(order);

	/*
		=========
		VARIABLES
		=========
	*/

	function addVariable(variableName: string, destinationIndex?: number): VariablesOrder {
		const variableItem = createOrderItem(variableName);

		if (destinationIndex !== undefined) {
			return arrayUtils.insert(newOrder, destinationIndex, variableItem);
		}

		newOrder.push(variableItem);

		return newOrder;
	}

	function addVariables(variableNames: string[], destinationIndex?: number): VariablesOrder {
		const variableItems = variableNames.map(variableName => createOrderItem(variableName));

		if (destinationIndex !== undefined) {
			return arrayUtils.insertMany(newOrder, destinationIndex, variableItems);
		}

		newOrder.push(...variableItems);

		return newOrder;
	}

	function removeVariable(variableName: string): VariablesOrder {
		const itemIndex = getVariableIndex(variableName);

		if (itemIndex !== -1) return arrayUtils.remove(newOrder, itemIndex);

		return newOrder;
	}

	function removeVariables(variableNames: string[]): VariablesOrder {
		const filteredNewOrder = newOrder.filter(item => {
			if (isVariableOrderItem(item)) {
				const variableName = item.variable;

				return !variableNames.includes(variableName);
			}

			return true;
		});

		return filteredNewOrder;
	}

	/*
		======
		GROUPS
		======
	*/

	function addGroup(groupName: string, destinationIndex?: number): VariablesOrder {
		const groupItem = createOrderItem(groupName, { group: true });

		if (destinationIndex !== undefined) {
			return arrayUtils.insert(newOrder, destinationIndex, groupItem);
		}

		newOrder.push(groupItem);

		return newOrder;
	}

	function removeGroup(
		groupName: string,
		variablesBelongingToGroup: string[],
		options?: { removeVariables: boolean }
	): VariablesOrder {
		const itemIndex = getGroupIndex(groupName);

		if (itemIndex !== -1) {
			const variableItems = variablesBelongingToGroup.map(variableName =>
				createOrderItem(variableName)
			);

			// DELETE GROUP
			const orderWithoutGroup = arrayUtils.remove(newOrder, itemIndex);

			if (options?.removeVariables) return orderWithoutGroup;

			// RELEASE GROUP VARIABLES IN THE SAME INDEX
			const orderWithVariables = arrayUtils.insertMany(
				orderWithoutGroup,
				itemIndex,
				variableItems
			);

			return orderWithVariables;
		}

		return newOrder;
	}

	/*
		=============
		VARIABLE SETS
		=============
	*/

	function addVariableSet(setName: string, destinationIndex?: number): VariablesOrder {
		const variableSetItem = createOrderItem(setName, { variableSet: true });

		if (destinationIndex !== undefined) {
			return arrayUtils.insert(newOrder, destinationIndex, variableSetItem);
		}

		newOrder.push(variableSetItem);

		return newOrder;
	}

	// TODO: CATER FOR NOT DELETING VARIABLE SET CONTENT
	function removeVariableSet(setName: string): VariablesOrder {
		const itemIndex = getVariableSetIndex(setName);

		if (itemIndex !== -1) return arrayUtils.remove(newOrder, itemIndex);

		return newOrder;
	}

	/*
		============
		ORDER CHANGE
		============
	*/

	function moveItem(sourceIndex: number, destinationIndex: number): VariablesOrder {
		return arrayUtils.move(newOrder, sourceIndex, destinationIndex);
	}

	/*
		=========
		INTERNALS
		=========
	*/

	function getVariableIndex(variableName: string) {
		return newOrder.findIndex(
			item => isVariableOrderItem(item) && item.variable === variableName
		);
	}

	function getGroupIndex(groupName: string) {
		return newOrder.findIndex(item => isGroupOrderItem(item) && item.group === groupName);
	}

	function getVariableSetIndex(setName: string) {
		return newOrder.findIndex(item => isVariableSetOrderItem(item) && item.set === setName);
	}

	function createOrderItem(
		name: string,
		itemType?: RequireOnlyOne<{ group?: boolean; variableSet?: boolean }>
	): VariablesOrderItem {
		const { group = false, variableSet = false } = itemType ?? {};

		if (group) return { group: name };

		if (variableSet) return { set: name };

		return { variable: name };
	}

	return {
		// VARIABLES
		addVariable,
		addVariables,
		removeVariable,
		removeVariables,
		// GROUPS
		addGroup,
		removeGroup,
		// VARIABLE SETS,
		addVariableSet,
		removeVariableSet,
		// ORDER CHANGE
		moveItem
	};
}

export function buildVariablesRichData(
	variablesData: VariablesData,
	options?: {
		omit?: RequireAtLeastOne<{
			/**
			 * system PROM generated variables
			 */
			promGenerated?: boolean;
			/**
			 * restricted variables (old: personal data)
			 */
			restricted?: boolean;
			/**
			 * variable type(s)
			 */
			types?: VariableType[];
			/**
			 * variable names
			 */
			names?: string[];
		}>;
	}
): VariablesRichData {
	const {
		variablesMap: originalVariablesMap,
		groupsMap: originalGroupsMap,
		variableSetsMap: originalVariableSetsMap,
		order: originalOrder
	} = variablesData;

	const { omit } = options ?? {};

	const variables: Variable[] = [];
	const variablesMap = cloneDeep(originalVariablesMap);
	const variableNames: string[] = [];

	const groups: Group[] = [];
	const groupsMap = cloneDeep(originalGroupsMap);
	const groupNames: string[] = [];

	const groupsOutsideSets: Group[] = [];
	const groupNamesOutsideSets: string[] = [];

	const variableSets: VariableSet[] = [];
	const variableSetsMap = cloneDeep(originalVariableSetsMap);
	const variableSetNames: string[] = [];

	const variablesOutsideSets: Variable[] = [];
	const variableNamesOutsideSets: string[] = [];

	const variablesDataArray: VariablesDataArray = [];

	let order = cloneDeep(originalOrder);

	variablesOrderIterator(
		order,
		variableName => {
			if (omitVariable(variableName)) {
				delete variablesMap[variableName];

				return;
			}

			const variable = variablesMap[variableName];

			variables.push(variable);
			variableNames.push(variable.name);
			variablesOutsideSets.push(variable);
			variableNamesOutsideSets.push(variable.name);
			variablesDataArray.push(variable);
		},
		groupName => {
			const group = groupsMap[groupName];

			const groupData: GroupData = {
				groupName: group.groupName,
				groupLabel: group.groupLabel,
				groupVariables: []
			};

			group.variablesBelongingToGroup.forEach(variableName => {
				if (omitVariable(variableName)) {
					delete variablesMap[variableName];

					return;
				}

				const variable = variablesMap[variableName];

				variables.push(variable);
				variableNames.push(variableName);
				variablesOutsideSets.push(variable);
				variableNamesOutsideSets.push(variableName);
				groupData.groupVariables.push(variable);
			});

			group.variablesBelongingToGroup = group.variablesBelongingToGroup.filter(
				variableName => {
					if (omitVariable(variableName)) return false;

					return true;
				}
			);

			groups.push(group);
			groupNames.push(group.groupName);
			groupsOutsideSets.push(group);
			groupNamesOutsideSets.push(group.groupName);
			variablesDataArray.push(groupData);
		},
		setName => {
			const variableSet = variableSetsMap[setName];

			const variableSetData: VariableSetData = {
				setName: variableSet.setName,
				setLabel: variableSet.setLabel,
				setData: [],
				identifier: {
					variableName: variableSet.identifier.variableName
				},
				aggregationRules: variableSet.aggregationRules
			};

			variablesOrderIterator(
				variableSet.setOrder,
				variableName => {
					if (omitVariable(variableName)) {
						delete variablesMap[variableName];

						return;
					}

					const variable = variablesMap[variableName];

					variables.push(variable);
					variableNames.push(variableName);
					variableSetData.setData.push(variable);
				},
				groupName => {
					const group = groupsMap[groupName];

					const groupData: GroupData = {
						groupName: group.groupName,
						groupLabel: group.groupLabel,
						groupVariables: []
					};

					group.variablesBelongingToGroup.forEach(variableName => {
						if (omitVariable(variableName)) {
							delete variablesMap[variableName];

							return;
						}

						const variable = variablesMap[variableName];

						variables.push(variable);
						variableNames.push(variableName);
						groupData.groupVariables.push(variable);
					});

					group.variablesBelongingToGroup = group.variablesBelongingToGroup.filter(
						variableName => {
							if (omitVariable(variableName)) return false;

							return true;
						}
					);

					groups.push(group);
					groupNames.push(group.groupName);
					variableSetData.setData.push(groupData);
				},
				// VARIABLE SET - NO NESTING SUPPORTED - OMIT
				() => null
			);

			variableSets.push(variableSet);
			variableSetNames.push(variableSet.setName);
			variablesDataArray.push(variableSetData);
		}
	);

	order = order.filter(item => {
		if (isVariableOrderItem(item)) {
			const { variable: variableName } = item;

			if (omitVariable(variableName)) return false;

			return true;
		}

		return true;
	});

	function omitVariable(variableName: string) {
		const { promGenerated = false, restricted = false, types = [], names = [] } = omit ?? {};

		const variable = originalVariablesMap[variableName];

		let canOmit = false;

		if (promGenerated && isVariablePromGenerated({ specialization: variable.specialization }))
			canOmit = true;

		if (restricted && variable.personalData) canOmit = true;

		if (types.length) canOmit = types.includes(variable.type) || canOmit;

		if (names.length) canOmit = names.includes(variable.name) || canOmit;

		return canOmit;
	}

	const hasVariables = variables.length > 0;
	const hasGroups = groups.length > 0;
	const hasVariableSets = variableSets.length > 0;

	const groupedVariableNames = groups.map(group => group.variablesBelongingToGroup).flat();
	const groupedVariables = groupedVariableNames.map(
		groupedVariableName => variablesMap[groupedVariableName]
	);

	const ungroupedVariableNames = variableNames.filter(
		variableName => !groupedVariableNames.includes(variableName)
	);
	const ungroupedVariables = ungroupedVariableNames.map(
		ungroupedVariableName => variablesMap[ungroupedVariableName]
	);

	const variablesRichData: VariablesRichData = {
		variables,
		variablesMap,
		variableNames,
		/////////////
		groups,
		groupsMap,
		groupNames,
		/////////////
		groupsOutsideSets,
		groupNamesOutsideSets,
		/////////////
		variableSets,
		variableSetsMap,
		variableSetNames,
		/////////////
		variablesOutsideSets,
		variableNamesOutsideSets,
		/////////////
		variablesDataArray,
		/////////////
		order,
		/////////////
		hasVariables,
		hasGroups,
		hasVariableSets,
		/////////////
		groupedVariableNames,
		groupedVariables,
		ungroupedVariableNames,
		ungroupedVariables
	};

	return variablesRichData;
}
interface VariablePromGeneratedPayload {
	specialization?: PROMSystemGenerated;
	systemGenerated?: boolean;
}
export function isVariablePromGenerated({
	specialization,
	systemGenerated
}: VariablePromGeneratedPayload) {
	const surveyGeneratedVariableSpecialization = Object.values(PROMSystemGenerated).includes(
		specialization as PROMSystemGenerated
	);

	return systemGenerated || surveyGeneratedVariableSpecialization;
}

export function variablesOrderIterator(
	order: VariablesOrder,
	getVariableName: (variableName: string, index: number) => void,
	getGroupName: (groupName: string, index: number) => void,
	getVariableSetName: (setName: string, index: number) => void
) {
	order.forEach((item, index) => {
		if (isVariableOrderItem(item)) {
			const { variable: variableName } = item;

			getVariableName(variableName, index);
		}
		if (isGroupOrderItem(item)) {
			const { group: groupName } = item;

			getGroupName(groupName, index);
		}
		if (isVariableSetOrderItem(item)) {
			const { set: setName } = item;

			getVariableSetName(setName, index);
		}
	});
}

export function isVariableOrderItem(item: VariablesOrderItem): item is { variable: string } {
	return 'variable' in item;
}

export function isGroupOrderItem(item: VariablesOrderItem): item is { group: string } {
	return 'group' in item;
}

export function isVariableSetOrderItem(item: VariablesOrderItem): item is { set: string } {
	return 'set' in item;
}

export function variablesDataArrayIterator(
	variablesDataArray: VariablesDataArray,
	getVariable: (variable: Variable, index: number) => void,
	getGroupData: (groupData: GroupData, index: number) => void,
	getVariableSetData: (variableSetData: VariableSetData, index: number) => void
) {
	variablesDataArray.forEach((item, index) => {
		if (isVariable(item)) return getVariable(item, index);

		if (isGroupData(item)) return getGroupData(item, index);

		if (isVariableSetData(item)) return getVariableSetData(item, index);
	});
}

export function isVariable(item: VariablesDataArrayItem): item is Variable {
	const variableKeys: (keyof Variable)[] = ['name', 'label', 'type'];

	return variableKeys.every(key => key in item);
}

export function isGroupData(item: VariablesDataArrayItem): item is GroupData {
	const groupKeys: (keyof GroupData)[] = ['groupName', 'groupLabel', 'groupVariables'];

	return groupKeys.every(key => key in item);
}

export function isVariableSetData(item: VariablesDataArrayItem): item is VariableSetData {
	const variableSetKeys: (keyof VariableSetData)[] = [
		'setName',
		'setLabel',
		'setData',
		'identifier',
		'aggregationRules'
	];

	return variableSetKeys.every(key => key in item);
}

/**
 * It iterates over the variables data list based on the `order` prop with automatic casting of types.
 *
 * @param variablesDataArray `variablesMap`, `groupsMap`, `order`
 * @param getVariable iteratable variable
 * @param getGroupData iteratable group
 * @param getVariableSetData iteratable variable set
 *
 * @returns an array of JSX Elements returned inside the `getVariable`, `getGroupData` and `getVariableSetData`
 */
export function variablesDataArrayJSXIterator(
	variablesDataArray: VariablesDataArray,
	getVariable: (variable: Variable, index: number) => React.ReactNode,
	getGroupData: (groupData: GroupData, index: number) => React.ReactNode,
	getVariableSetData: (variableSetData: VariableSetData, index: number) => React.ReactNode
) {
	const JSXElements: React.ReactNode[] = [];

	variablesDataArrayIterator(
		variablesDataArray,
		(variable, index) => {
			const JSXElement = getVariable(variable, index);

			if (JSXElement) JSXElements.push(JSXElement);
		},
		(groupData, index) => {
			const JSXElement = getGroupData(groupData, index);

			if (JSXElement) JSXElements.push(JSXElement);
		},
		(variableSetData, index) => {
			const JSXElement = getVariableSetData(variableSetData, index);

			if (JSXElement) JSXElements.push(JSXElement);
		}
	);

	return JSXElements;
}

export function isVariableVisible(variableName: string, variableVisibilityMap: BooleanMap) {
	const hasVisibilityCondition = variableName in variableVisibilityMap;
	const isVisible = variableVisibilityMap[variableName];

	if (hasVisibilityCondition) return isVisible;

	return true;
}

export function filterVariablesDataArrayBySearchTerm(
	variablesDataArray: VariablesDataArray,
	searchTerm: string,
	{ translate }: { translate: TranslateFunction }
): VariablesDataArray {
	let filtered = cloneDeep(variablesDataArray);

	const translatedVariableTypeMap = translateVariableTypeMap(translate);

	const isSearchTermValid = searchTerm.trim().length > 0;

	if (isSearchTermValid) {
		filtered = filtered.filter(item => {
			// GROUP DATA
			if (isGroupData(item)) {
				const group = item;
				const keywords: string[] = [group.groupLabel];
				const groupLabelMatched = hasMatches({ searchTerm, keywords });

				// FILTER GROUP VARIABLES
				if (!groupLabelMatched) {
					group.groupVariables = group.groupVariables.filter(variable => {
						const keywords: string[] = [
							variable.label,
							variable.description,
							translatedVariableTypeMap[variable.type]
						];

						const variableMatched = hasMatches({
							searchTerm,
							keywords
						});

						return variableMatched;
					});
				}
				const groupVariablesMatched = group.groupVariables.length > 0;
				const groupMatched = groupLabelMatched || groupVariablesMatched;

				return groupMatched;
			}

			// VARIABLE SET DATA
			if (isVariableSetData(item)) {
				const variableSetData = item;

				variableSetData.setData = variableSetData.setData.filter(variableSetDataItem => {
					// GROUP DATA
					if (isGroupData(variableSetDataItem)) {
						const group = variableSetDataItem;

						// FILTER GROUP VARIABLES
						group.groupVariables = group.groupVariables.filter(variable => {
							const keywords: string[] = [
								variable.label,
								variable.description,
								translatedVariableTypeMap[variable.type]
							];

							const variableMatched = hasMatches({
								searchTerm,
								keywords
							});

							return variableMatched;
						});

						const keywords: string[] = [group.groupLabel];

						const groupLabelMatched = hasMatches({
							searchTerm,
							keywords
						});
						const groupVariablesMatched = group.groupVariables.length > 0;

						const groupMatched = groupLabelMatched || groupVariablesMatched;

						return groupMatched;
					}

					// VARIABLE
					const variable = variableSetDataItem;

					const keywords: string[] = [
						variable.label,
						variable.description,
						translatedVariableTypeMap[variable.type]
					];

					const variableMatched = hasMatches({
						searchTerm,
						keywords
					});

					return variableMatched;
				});

				const keywords: string[] = [variableSetData.setLabel];

				const variableSetLabelMatched = hasMatches({
					searchTerm,
					keywords
				});
				const variableSetDataMatched = variableSetData.setData.length > 0;

				const variableSetMatched = variableSetLabelMatched || variableSetDataMatched;

				return variableSetMatched;
			}

			// VARIABLE
			const variable = item;

			const keywords: string[] = [
				variable.label,
				variable.description,
				translatedVariableTypeMap[variable.type]
			];

			const variableMatched = hasMatches({ searchTerm, keywords });

			return variableMatched;
		});
	}

	return filtered;
}

export function parseApiVariablesData(apiVariablesData: ApiVariablesData): VariablesData {
	const { variables: apiVariables, groups: apiGroups, sets: apiSets, order } = apiVariablesData;

	const variablesData = initVariablesData({ order });

	Object.values(apiVariables).forEach(apiVariable => {
		const variable = parseApiVariable(apiVariable);

		variablesData.variablesMap[variable.name] = variable;
	});

	Object.values(apiGroups).forEach(apiGroup => {
		const group = parseApiGroup(apiGroup);

		variablesData.groupsMap[group.groupName] = group;
	});

	Object.values(apiSets).forEach(apiVariableSet => {
		const variableSet = parseApiVariableSet(apiVariableSet);

		variablesData.variableSetsMap[variableSet.setName] = variableSet;
	});

	return variablesData;
}

export function buildVariablesDataFromStoreData(
	storeVariablesData: StoreVariablesData
): VariablesData {
	const { variables, groups, variableSets, order } = storeVariablesData;

	const variablesData = initVariablesData({
		variablesMap: variables.byName,
		groupsMap: groups.byName,
		variableSetsMap: variableSets.byName,
		order
	});

	return variablesData;
}

export function buildVariablesDataFromArray(array: VariablesDataArray) {
	return (array as (Variable | GroupData)[]).reduce<Record<string, Variable | GroupData>>(
		(acc, current) => {
			'name' in current ? (acc[current.name] = current) : (acc[current.groupName] = current);
			return acc;
		},
		{}
	);
}

export function initVariablesData(fields: Partial<VariablesData> = {}): VariablesData {
	const variablesData: VariablesData = {
		variablesMap: fields.variablesMap ?? {},
		groupsMap: fields.groupsMap ?? {},
		variableSetsMap: fields.variableSetsMap ?? {},
		order: fields.order ?? []
	};

	return variablesData;
}

interface IsVariableInSetProps {
	variableName: string;
	variablesData: VariablesData;
}
/**
 * Get `setName` by `variableName`
 *
 * @returns `setName` if found; else `undefined`
 */
export function isVariableInSet({
	variableName,
	variablesData
}: IsVariableInSetProps): string | undefined {
	const { groupsMap, variableSetsMap } = variablesData;

	const variableSet = Object.values(variableSetsMap).find(variableSet => {
		const setVariableNames = getVariableSetVariableNames(variableSet, {
			groupsMap
		});

		return setVariableNames.includes(variableName);
	});

	return variableSet?.setName;
}

interface IsVariableGroupInSetProps {
	groupName: string;
	variablesData: VariablesData;
}
/**
 * Get `setName` by `variableName`
 *
 * @returns `setName` if found; else `undefined`
 */
export function isVariableGroupInSet({
	groupName,
	variablesData
}: IsVariableGroupInSetProps): string | undefined {
	const { variableSetsMap } = variablesData;

	const variableSet = Object.values(variableSetsMap).find(variableSet => {
		const setGroupNames: string[] = [];

		variableSet.setOrder.forEach(item => {
			if (isGroupOrderItem(item)) {
				const { group: groupName } = item;

				setGroupNames.push(groupName);
			}
		});

		return setGroupNames.includes(groupName);
	});

	return variableSet?.setName;
}

interface IsVariableInGroupProps {
	variableName: string;
	groupsMap: GroupsMap;
}
/**
 * Get `setName` by `variableName`
 *
 * @returns `setName` if found; else `undefined`
 */
export function isVariableInGroup({
	variableName,
	groupsMap
}: IsVariableInGroupProps): string | undefined {
	const groups = Object.values(groupsMap);

	const group = groups.find(group => group.variablesBelongingToGroup.includes(variableName));

	return group?.groupName;
}

export function buildVariableSetVariablesData(input: {
	setName: string;
	variablesData: VariablesData;
}): VariablesData {
	const { variablesMap, groupsMap, variableSetsMap } = input.variablesData;

	const variableSet = variableSetsMap[input.setName];

	const variablesData = initVariablesData({ order: variableSet.setOrder });

	// BUILD `variablesData` JUST FROM `variableSet.setOrder`
	variablesOrderIterator(
		variableSet.setOrder,
		variableName => {
			const variable = variablesMap[variableName];

			variablesData.variablesMap[variableName] = variable;
		},
		groupName => {
			const group = groupsMap[groupName];

			variablesData.groupsMap[groupName] = groupsMap[groupName];
			group.variablesBelongingToGroup.forEach(groupVariableName => {
				const variable = variablesMap[groupVariableName];

				variablesData.variablesMap[groupVariableName] = variable;
			});
		},
		// VARIABLE SET - OMIT
		() => null
	);

	return variablesData;
}

export function getGroupVariables(
	group: Group,
	{ variablesMap }: { variablesMap: VariablesMap }
): Variable[] {
	const groupVariables: Variable[] = [];

	group.variablesBelongingToGroup.forEach(variableName => {
		if (variableName in variablesMap) {
			const groupVariable = variablesMap[variableName];

			groupVariables.push(groupVariable);
		}
	});

	return groupVariables;
}

export function getGroupDataVariableNames(group: GroupData): string[] {
	return group.groupVariables.map(variable => variable.name);
}

export function getVariableSetVariableNames(
	variableSet: VariableSet,
	{ groupsMap }: { groupsMap: GroupsMap }
): string[] {
	const setVariableNames: string[] = [];

	variablesOrderIterator(
		variableSet.setOrder,
		// VARIABLE
		variableName => setVariableNames.push(variableName),
		// GROUP
		groupName => {
			const group = groupsMap[groupName];

			setVariableNames.push(...group.variablesBelongingToGroup);
		},
		// VARIABLE SET - OMIT
		() => null
	);

	return setVariableNames;
}

export function getVariableSetGroupNames(variableSet: VariableSet): string[] {
	const setGroupNames: string[] = [];

	variablesOrderIterator(
		variableSet.setOrder,
		// VARIABLE
		() => null,
		// GROUP
		groupName => setGroupNames.push(groupName),
		// VARIABLE SET - OMIT
		() => null
	);

	return setGroupNames;
}

export function getVariableSetDataVariableNames(variableSetData: VariableSetData): string[] {
	const setVariableNames: string[] = [];

	variablesDataArrayIterator(
		variableSetData.setData,
		// VARIABLE
		variable => setVariableNames.push(variable.name),
		// GROUP
		groupData => {
			const groupVariableNames = getGroupDataVariableNames(groupData);

			setVariableNames.push(...groupVariableNames);
		},
		// VARIABLE SET - OMIT
		() => null
	);

	return setVariableNames;
}

/**
 * Used for granular updates - serves concurrency
 */
export function preparePartialApiVariableSet(
	initialVariableSet: VariableSet,
	variableSet: PartialVariableSet
): PartialApiVariableSet {
	const updatedFields = objectDifference(initialVariableSet, variableSet);

	const { setLabel, identifier } = updatedFields;

	const apiVariableSet: PartialApiVariableSet = {
		setName: variableSet.setName,
		...(setLabel !== undefined && { setLabel }),
		...(identifier !== undefined && {
			identifyingVariable: identifier.variableName
		})
	};

	return apiVariableSet;
}

export function prepareVariablesDataForBulkDelete(input: {
	selected: {
		variableNames: string[];
		groupNames: string[];
		variableSetNames: string[];
	};
	variablesData: VariablesData;
}) {
	const { selected, variablesData } = input;

	const dataToDelete: {
		variables: VariableLocation[];
		groups: GroupLocation[];
		variableSets: {
			setName: string;
		}[];
	} = {
		variables: [],
		groups: [],
		variableSets: []
	};

	const { variablesLocation: variableLocation, groupsLocation: groupLocation } =
		buildVariablesDataLocation(variablesData);

	selected.variableNames.forEach(variableName => {
		const variableToDelete: VariableLocation = {
			variableName
		};

		if (variableName in variableLocation) {
			const { setName, groupName } = variableLocation[variableName];

			if (setName && groupName) {
				variableToDelete.from = {
					set: {
						setName,
						group: {
							groupName
						}
					}
				};
			}

			if (setName && !groupName) {
				variableToDelete.from = {
					set: {
						setName
					}
				};
			}

			if (!setName && groupName) {
				variableToDelete.from = {
					group: {
						groupName
					}
				};
			}
		}

		dataToDelete.variables.push(variableToDelete);
	});

	selected.groupNames.forEach(groupName => {
		const groupToDelete: GroupLocation = {
			groupName
		};

		if (groupName in groupLocation) {
			const { setName } = groupLocation[groupName];

			if (setName) {
				groupToDelete.from = {
					set: {
						setName
					}
				};
			}
		}

		dataToDelete.groups.push(groupToDelete);
	});

	selected.variableSetNames.forEach(setName => {
		dataToDelete.variableSets.push({ setName });
	});

	return dataToDelete;
}

export function prepareVariablesDataForMove(input: {
	selected: {
		variableNames: string[];
		groupNames: string[];
	};
	variablesData: VariablesData;
}) {
	const { selected, variablesData } = input;

	const dataToMove: {
		variables: VariableLocation[];
		groups: GroupLocation[];
	} = {
		variables: [],
		groups: []
	};

	const { variablesLocation: variableLocation, groupsLocation: groupLocation } =
		buildVariablesDataLocation(variablesData);

	selected.variableNames.forEach(variableName => {
		const variableToMove: VariableLocation = {
			variableName
		};

		if (variableName in variableLocation) {
			const { setName, groupName } = variableLocation[variableName];

			if (setName && groupName) {
				variableToMove.from = {
					set: {
						setName,
						group: {
							groupName
						}
					}
				};
			}

			if (setName && !groupName) {
				variableToMove.from = {
					set: {
						setName
					}
				};
			}

			if (!setName && groupName) {
				variableToMove.from = {
					group: {
						groupName
					}
				};
			}
		}

		dataToMove.variables.push(variableToMove);
	});

	selected.groupNames.forEach(groupName => {
		const groupToMove: GroupLocation = {
			groupName
		};

		if (groupName in groupLocation) {
			const { setName } = groupLocation[groupName];

			if (setName) {
				groupToMove.from = {
					set: {
						setName
					}
				};
			}
		}

		dataToMove.groups.push(groupToMove);
	});

	return dataToMove;
}

export function prepareApiVariablesDataForMove(input: {
	variables: VariableLocation[];
	groups: GroupLocation[];
}) {
	const { variables, groups } = input;

	const dataToMove: {
		mainList: {
			variables: VariableLocation[];
			groups: GroupLocation[];
			hasData: boolean;
		};
		variableSets: GenericMap<{
			setName: string;
			variables: VariableLocation[];
			groups: GroupLocation[];
		}>;
	} = {
		mainList: {
			variables: [],
			groups: [],
			hasData: false
		},
		variableSets: {}
	};

	variables.forEach(variableToMove => {
		const { from } = variableToMove;

		if (from?.set) {
			const { setName } = from.set;

			initVariableSetStructure(setName);

			dataToMove.variableSets[setName].variables.push(variableToMove);

			return;
		}

		dataToMove.mainList.variables.push(variableToMove);
	});

	groups.forEach(groupToMove => {
		const { from } = groupToMove;

		if (from?.set) {
			const { setName } = from.set;

			initVariableSetStructure(setName);

			dataToMove.variableSets[setName].groups.push(groupToMove);

			return;
		}

		dataToMove.mainList.groups.push(groupToMove);
	});

	function initVariableSetStructure(setName: string) {
		if (setName in dataToMove.variableSets) return;

		dataToMove.variableSets[setName] = {
			setName,
			variables: [],
			groups: []
		};
	}

	dataToMove.mainList.hasData =
		dataToMove.mainList.variables.length + dataToMove.mainList.groups.length > 0;

	return {
		mainList: dataToMove.mainList,
		variableSets: Object.values(dataToMove.variableSets)
	};
}

export function prepareApiVariablesDataForBulkDelete(input: {
	variables: VariableLocation[];
	groups: GroupLocation[];
	variableSets: {
		setName: string;
	}[];
}) {
	const { variables, groups, variableSets } = input;

	const dataToDelete: {
		mainList: {
			variables: VariableLocation[];
			groups: GroupLocation[];
			hasData: boolean;
		};
		variableSets: GenericMap<{
			setName: string;
			variables: VariableLocation[];
			groups: GroupLocation[];
			deleteSet: boolean;
		}>;
	} = {
		mainList: {
			variables: [],
			groups: [],
			hasData: false
		},
		variableSets: {}
	};

	variables.forEach(variableToDelete => {
		const { from } = variableToDelete;

		if (from?.set) {
			const { setName } = from.set;

			initVariableSetStructure(setName);

			dataToDelete.variableSets[setName].variables.push(variableToDelete);

			return;
		}

		dataToDelete.mainList.variables.push(variableToDelete);
	});

	groups.forEach(groupToDelete => {
		const { from } = groupToDelete;

		if (from?.set) {
			const { setName } = from.set;

			initVariableSetStructure(setName);

			dataToDelete.variableSets[setName].groups.push(groupToDelete);

			return;
		}

		dataToDelete.mainList.groups.push(groupToDelete);
	});

	variableSets.forEach(variableSetToDelete => {
		const { setName } = variableSetToDelete;

		initVariableSetStructure(setName);

		dataToDelete.variableSets[setName].deleteSet = true;
	});

	function initVariableSetStructure(setName: string) {
		if (setName in dataToDelete.variableSets) return;

		dataToDelete.variableSets[setName] = {
			setName,
			variables: [],
			groups: [],
			deleteSet: false
		};
	}

	dataToDelete.mainList.hasData =
		dataToDelete.mainList.variables.length + dataToDelete.mainList.groups.length > 0;

	return {
		mainList: dataToDelete.mainList,
		variableSets: Object.values(dataToDelete.variableSets)
	};
}

export function parseVariableSetAggregatedValue(
	value: string,
	aggregationRule: AggregationRule,
	metadata: {
		variablesMap: VariablesMap;
		dateTimeFormat: DateTimeFormat;
	},
	translateMap?: StringMap
) {
	const { variablesMap, dateTimeFormat } = metadata;

	let parsedValue = value;

	const shouldParseValue = ![AggregationRuleType.Earliest, AggregationRuleType.Latest].includes(
		aggregationRule.rule.type
	);

	if (shouldParseValue) parsedValue = numberToFixed(value);

	const aggregatorVariable = variablesMap[aggregationRule.aggregator.variableName];

	if (value) {
		// `timeDuration`
		if (
			aggregatorVariable.type === VariableType.TimeDuration &&
			aggregatorVariable.durationFormat
		) {
			// casting because function cannot return null unless `value` arg is `null` but it cannot be here - check above makes sure of that
			parsedValue = parseTimeDurationEntryCell(
				getTimeDurationStringFromMicroseconds(
					Number(value),
					aggregatorVariable.durationFormat
				) as string,
				aggregatorVariable.durationFormat,
				...(translateMap ? [translateMap] : [])
			);
		}

		// `date`
		if (aggregatorVariable.type === VariableType.Date) {
			parsedValue = format(new Date(value), DATE_FORMAT);
		}

		// `datetime`
		if (aggregatorVariable.type === VariableType.DateTime) {
			parsedValue = format(new Date(value), dateTimeFormatMap[dateTimeFormat]);
		} // `categoryMultiple`
		if (Array.isArray(value)) {
			const labels = mapVariableCategoryValuesToLabels(value, aggregatorVariable);

			return labels.join(', ');
		}

		// `category`
		if (aggregatorVariable.type === VariableType.Category) {
			const label = mapVariableCategoryValueToLabel(value, aggregatorVariable);

			return label;
		}
	}

	return parsedValue;
}

export function buildVariablesDataLocation(variablesData: VariablesData) {
	const variablesDataLocation: VariablesDataLocation = {
		variablesLocation: {},
		groupsLocation: {}
	};

	const { variablesLocation, groupsLocation } = variablesDataLocation;

	const { variablesDataArray } = buildVariablesRichData(variablesData);

	variablesDataArrayIterator(
		variablesDataArray,
		// VARIABLE
		() => null,
		// GROUP
		groupData => {
			const { groupName, groupVariables } = groupData;
			groupVariables.forEach(variable => {
				variablesLocation[variable.name] = { groupName };
			});
		},
		// VARIABLE SET
		variableSetData => {
			const { setName, setData } = variableSetData;

			variablesDataArrayIterator(
				setData,
				// VARIABLE
				variable => {
					variablesLocation[variable.name] = { setName };
				},
				// GROUP
				groupData => {
					const { groupName, groupVariables } = groupData;

					groupVariables.forEach(variable => {
						variablesLocation[variable.name] = {
							setName,
							groupName
						};
					});

					groupsLocation[groupName] = { setName };
				},
				// VARIABLE SET
				() => null
			);
		}
	);

	return variablesDataLocation;
}

/**
 * Checks if the variable is:
 * - category
 * - has fixed values
 * - is used as dependency rule supplier
 * - is used as dependant in a dependency rule
 *
 * @returns {boolean} boolean
 */
export function showVariableFixedValuesWarning(input: {
	variable: Variable;
	dependencies: Dependency[];
}): boolean {
	const { variable, dependencies } = input;

	let isUsed = false;

	const isCategoryVariable = [VariableType.Category, VariableType.CategoryMultiple].includes(
		variable.type
	);
	const hasFixedCategories = variable.fixedCategories;

	if (isCategoryVariable && hasFixedCategories) {
		dependenciesLoop: for (let index = 0; index < dependencies.length; index++) {
			const dependency = dependencies[index];

			if (dependency.supplierVariableName === variable.name) {
				isUsed = true;

				break;
			}

			const isFilteringType = dependency.dependencyType === DependencyType.Filtering;

			if (isFilteringType) {
				for (let index = 0; index < dependency.dependantVariables.length; index++) {
					const dependantVariable = dependency.dependantVariables[index];

					const { dependantVariableName } = dependantVariable;

					if (dependantVariableName === variable.name) {
						isUsed = true;

						break dependenciesLoop;
					}
				}
			}
		}
	}

	return isUsed;
}

export function getFirstAggregationRule(
	aggregationRules: AggregationRule[]
): AggregationRule | undefined {
	return aggregationRules[0] ?? undefined;
}

export function getAggregatorVariableNameByAggregationRuleName(variableSetsMap: VariableSetsMap) {
	const map: StringMap = {};

	Object.values(variableSetsMap).forEach(variableSet => {
		const { aggregationRules } = variableSet;

		aggregationRules.forEach(aggregationRule => {
			const { name, aggregator } = aggregationRule;

			map[name] = aggregator.variableName;
		});
	});

	return map;
}

export function isFilterAggregationRule(columnName: string, variableSets: VariableSetsState) {
	return Object.values(variableSets.byName).some(v =>
		v.aggregationRules.some(r => r.name === columnName)
	);
}

export function getAggregationRuleTypeLabel(aggregationRule: AggregationRule): string {
	let label: string = aggregationRule.rule.type;

	// hackish mapping for `PRJCTS-5818`
	if (label === AggregationRuleType.Mean) label = 'mean';

	return label;
}

export function getSeriesAggregationLabel(aggregator: AggregationRule, variable: Variable) {
	return `${variable.label} (${getAggregationRuleTypeLabel(aggregator)})`;
}

export function getAggregationLabel(
	key: string,
	variablesMap: VariablesMap,
	variableSetsMap: VariableSetsMap
) {
	let setAggregationVariable: Variable | null = null;
	let aggregationRule: AggregationRule | null = null;
	for (const variableSet of Object.values(variableSetsMap)) {
		for (const aggRule of variableSet.aggregationRules) {
			if (aggRule.name === key) {
				setAggregationVariable = variablesMap[aggRule.aggregator.variableName];
				aggregationRule = aggRule;
				break;
			}
		}
	}
	return setAggregationVariable && aggregationRule
		? getSeriesAggregationLabel(aggregationRule, setAggregationVariable)
		: null;
}

export function getVariablesDataDifferences(
	variablesData: VariablesData,
	newVariablesData: VariablesData
) {
	const flatOrder = buildVariablesDataFlatOrder(variablesData);
	const newFlatOrder = buildVariablesDataFlatOrder(newVariablesData);

	const difference = {
		variables: getMapDifferences(variablesData.variablesMap, newVariablesData.variablesMap),
		groups: getMapDifferences(variablesData.groupsMap, newVariablesData.groupsMap),
		variableSets: getMapDifferences(
			variablesData.variableSetsMap,
			newVariablesData.variableSetsMap
		),
		order: {
			from: flatOrder,
			to: newFlatOrder,
			changed: !isEqual(flatOrder, newFlatOrder)
		}
	};

	return difference;
}

export function getMapDifferences<T>(map: GenericMap<T>, newMap: GenericMap<T>) {
	const difference: {
		created: T[];
		deleted: T[];
		updated: {
			from: T;
			to: T;
		}[];
	} = {
		created: [],
		deleted: [],
		updated: []
	};

	// initial map
	for (const key in map) {
		const item = map[key];

		// mutual
		if (key in newMap) {
			const newItem = newMap[key];

			if (!isEqual(item, newItem)) {
				difference.updated.push({
					from: item,
					to: newItem
				});
			}
		}
		// deleted
		else {
			difference.deleted.push(item);
		}
	}

	// new map
	for (const newKey in newMap) {
		const item = newMap[newKey];

		// created
		if (!(newKey in map)) {
			difference.created.push(item);
		}
	}

	return difference;
}

function buildVariablesDataFlatOrder(variablesData: VariablesData): string[] {
	const flatOrder: string[] = [];

	variablesOrderIterator(
		variablesData.order,
		// VARIABLE
		variableName => flatOrder.push(variableName),
		// GROUP
		groupName => {
			const group = variablesData.groupsMap[groupName];

			flatOrder.push(groupName, ...group.variablesBelongingToGroup);
		},
		// VARIABLE SET
		setName => {
			const variableSetVariablesData = buildVariableSetVariablesData({
				setName,
				variablesData
			});
			const variableSetFlatOrder = buildVariablesDataFlatOrder(variableSetVariablesData);

			flatOrder.push(setName, ...variableSetFlatOrder);
		}
	);

	return flatOrder;
}

/**
 * Must be kept up to date
 */
export function getActionTypesWithHydrateData() {
	return [
		// VARIABLE
		ActionTypes.CREATE_VARIABLE,
		ActionTypes.UPDATE_VARIABLE,
		ActionTypes.DELETE_VARIABLE,
		// GROUP
		ActionTypes.CREATE_GROUP,
		ActionTypes.UPDATE_GROUP,
		ActionTypes.DELETE_GROUP,
		// VARIABLE SET
		ActionTypes.CREATE_VARIABLE_SET,
		ActionTypes.UPDATE_VARIABLE_SET,
		ActionTypes.DELETE_VARIABLE_SET,
		// AGGREGATION RULES
		ActionTypes.CREATE_VARIABLE_SET_AGGREGATION_RULE,
		ActionTypes.UPDATE_VARIABLE_SET_AGGREGATION_RULE,
		ActionTypes.DELETE_VARIABLE_SET_AGGREGATION_RULE,
		// MOVE ACTIONS
		ActionTypes.MOVE_VARIABLE,
		ActionTypes.MOVE_GROUP,
		ActionTypes.MOVE_VARIABLE_SET,
		ActionTypes.MOVE_VARIABLE_SET_AGGREGATION_RULE,
		ActionTypes.ADD_VARIABLE_TO_GROUP,
		ActionTypes.ADD_VARIABLES_TO_GROUP,
		ActionTypes.REMOVE_VARIABLE_FROM_GROUP,
		ActionTypes.REMOVE_VARIABLES_FROM_GROUP,
		ActionTypes.MOVE_VARIABLE_INSIDE_GROUP,
		ActionTypes.MOVE_VARIABLE_BETWEEN_GROUPS,
		ActionTypes.MOVE_VARIABLES_BETWEEN_GROUPS,
		ActionTypes.ADD_VARIABLE_TO_SET,
		ActionTypes.REMOVE_VARIABLE_FROM_SET,
		ActionTypes.ADD_VARIABLE_GROUP_TO_SET,
		ActionTypes.REMOVE_VARIABLE_GROUP_FROM_SET,
		ActionTypes.MOVE_VARIABLE_BETWEEN_SETS,
		ActionTypes.MOVE_VARIABLE_GROUP_BETWEEN_SETS,
		// BULK DELTE
		ActionTypes.DELETE_BULK_VARIABLES_DATA,
		// CRUD - CATEGORIES
		ActionTypes.CREATE_VARIABLE_CATEGORY_VALUE,
		ActionTypes.CREATE_VARIABLE_CATEGORY_VALUES,
		ActionTypes.UPDATE_VARIABLE_CATEGORY_VALUE,
		ActionTypes.DELETE_VARIABLE_CATEGORY_VALUE,
		ActionTypes.DELETE_VARIABLE_CATEGORY_VALUES,
		ActionTypes.MOVE_VARIABLE_CATEGORY_VALUE
	];
}

/**
 * Used for granular updates - serves concurrency
 */
export function preparePartialVariableCategory(
	initialCategoryValue: VariableCategory,
	categoryValue: VariableCategory
): PartialVariableCategory {
	const updatedFields = objectDifference(initialCategoryValue, categoryValue);

	const { value, label, description } = updatedFields;

	const apiVariableCategory: PartialVariableCategory = {
		id: initialCategoryValue.id,
		...(value !== undefined && { value }),
		...(label !== undefined && { label }),
		...(description !== undefined && { description })
	};

	return apiVariableCategory;
}

export function initVariableCategory(fields: Partial<VariableCategory> = {}): VariableCategory {
	const variableCategory: VariableCategory = {
		id: fields.id ?? `draft_${generate()}`,
		value: fields.value ?? '',
		label: fields.label ?? '',
		description: fields.description ?? ''
	};

	return variableCategory;
}

/**
 * trims string values of: `value`, `label`, `description`
 */
export function parseVariableCategory(categoryValue: VariableCategory): VariableCategory {
	const parsedCategoryValue: VariableCategory = {
		id: categoryValue.id,
		value: categoryValue.value.trim(),
		label: categoryValue.label.trim(),
		description: categoryValue.description.trim()
	};

	return parsedCategoryValue;
}

/**
 * trims string values of: `value`, `label`, `description`
 */
export function parseVariableCategories(categoryValues: VariableCategory[]): VariableCategory[] {
	return categoryValues.map(parseVariableCategory);
}

/**
 * This will return main level variables only, or `variableSet` variables
 * if the user is creating or updating a variable within a `variableSet`
 */
export function getScopeVariables(input: {
	variables: Variable[];
	variablesData: VariablesData;
	destinationSetName: string | null;
}): Variable[] {
	const { variables, variablesData, destinationSetName } = input;

	if (destinationSetName !== null) {
		const variableSetVariablesData = buildVariableSetVariablesData({
			setName: destinationSetName,
			variablesData
		});

		const { variables: setVariables } = buildVariablesRichData(variableSetVariablesData);

		return setVariables;
	}

	const { variablesLocation } = buildVariablesDataLocation(variablesData);

	const mainLevelVariables = variables.filter(v => {
		if (v.name in variablesLocation) {
			const location = variablesLocation[v.name];

			return location.setName === undefined;
		}

		return true;
	});

	return mainLevelVariables;
}

/**
 * - map `value` to `label` if category and label found
 * - invalidate insexistent
 * @param value category value
 * @param variable variable to validate against
 */
export function mapVariableCategoryValueToLabel(
	value: string,
	variable: Variable
): string | undefined {
	const category = variable.categories.find(c => c.value === value);

	if (category) return category.label || category.value;
}

/**
 * - map `values` to `labels` if categories and labels pair found
 * - invalidate insexistent
 * @param values category values
 * @param variable variable to validate against
 */
export function mapVariableCategoryValuesToLabels(values: string[], variable: Variable) {
	return values.flatMap(value => mapVariableCategoryValueToLabel(value, variable) ?? []);
}

export function buildVariableCategoriesMap(
	categories: VariableCategory[],
	key: keyof VariableCategory = 'value'
) {
	return categories.reduce<GenericMap<VariableCategory>>((acc, category) => {
		acc[category[key]] = category;

		return acc;
	}, {});
}

export function buildVariablesMap(variables: Variable[]) {
	const map: VariablesMap = {};
	variables.forEach(variable => (map[variable.name] = variable));
	return map;
}

export function itemIsType(variable: Variable, types: VariableType[]) {
	return types.includes(variable.type);
}

export function groupHasForbiddenVariableType(
	group: Group,
	variablesMap: VariablesMap,
	types: VariableType[]
) {
	return group.variablesBelongingToGroup.find(variable =>
		types.includes(variablesMap[variable].type)
	);
}

export const generateCalculationId = (function () {
	let currIndex = 0;
	return function () {
		currIndex++;

		const newId = `${currIndex}`;

		return newId;
	};
})();

/**
 * USE CASE: aggregated variable is sent in analysis based on aggregation rule name
 * @param variableSetsMap
 * @returns mapping of rule name to aggregator variable name;
 */
export function buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap: VariableSetsMap) {
	return values(variableSetsMap).reduce<Record<string, AggregationRule>>((acc, set) => {
		set.aggregationRules.forEach(rule => {
			acc[rule.name] = rule;
		});
		return acc;
	}, {});
}

/**
 *
 * @param variablesData ALL variable data available
 * @param variableSetName target series to build variables data for
 * @returns (VariablesData) variables data isolated to series level
 */
export function buildSeriesLevelVariableData(
	variablesData: VariablesData,
	variableSetName?: string
) {
	const { variableSetsMap, variablesMap, groupsMap } = variablesData;
	if (!variableSetName) return initVariablesData();

	if (!variableSetsMap[variableSetName]) {
		console.error(
			`wrong variableSet key => ${variableSetName}. Key does not exist in variables data`
		);
		return initVariablesData();
	}

	return Object.values(variableSetsMap[variableSetName].setOrder).reduce(
		(acc, data) => {
			if (isVariableOrderItem(data)) {
				acc.variablesMap[data.variable] = variablesMap[data.variable];
				acc.order.push({ variable: data.variable });
			} else {
				groupsMap[data.group].variablesBelongingToGroup.forEach(variable => {
					acc.variablesMap[variable] = variablesMap[variable];
				});
				acc.groupsMap[data.group] = groupsMap[data.group];
				acc.order.push({ group: data.group });
			}
			return acc;
		},
		{
			groupsMap: {},
			order: [],
			variableSetsMap: {
				[variableSetName]: { ...variableSetsMap[variableSetName], aggregationRules: [] }
			},
			variablesMap: {}
		} as VariablesData
	);
}
/**
 *
 * @param selectItems select items array
 * @param variablesData VariablesData
 * @param allowedTypes types that should be extracted
 * @returns SelectItem[]
 * @usage used for merging select item types while preserving their original order
 */
export function mergeSelectItems(
	selectItems: SelectItemOrGroup[],
	variablesData: VariablesData,
	allowedTypes: VariableType[]
): SelectItemOrGroup[] {
	// INJECT SERIES AGGREGATION RULE INTO VARIABLES MAP
	// {...variablesMap, [aggregationRuleName]: AggregatorVariable}
	const variablesMap = cloneDeep(variablesData.variablesMap);
	const aggRuleToVariableMap = buildAggregationRuleNameToAggregatorVariableMap(
		variablesData.variableSetsMap
	);

	// inject rules into variablesMap
	Object.entries(aggRuleToVariableMap).forEach(([aggRuleName, rule]) => {
		variablesMap[aggRuleName] = variablesMap[rule.aggregator.variableName];
	});

	return selectItems.flatMap(item => {
		if (item.options) {
			const selectItem = item as SelectGroup<SelectItem>;

			const filteredItemOptions = (item.options as SelectItem[]).flatMap(item =>
				allowedTypes.includes(variablesMap[item.value].type) ? item : []
			);

			if (filteredItemOptions.length) {
				return { ...selectItem, options: filteredItemOptions };
			}

			return [];
		}

		const selectItem = item as SelectItem;
		if (allowedTypes.includes(variablesMap[selectItem.value].type)) return item;
		return [];
	});
}
